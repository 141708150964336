import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword
} from 'firebase/auth';

// Components
import Button from "../../components/button/Button.js";
import PasswordInput from '../../components/passwordInput/PasswordInput.jsx';

// Sass
import './ChangePassword.scss';

export default function ChangePassword() {
  const [email, setEmail] = useState('');
  const [oldPW, setOldPW] = useState('');
  const [newPW, setNewPW] = useState('');
  const [newPWAgaing, setNewPWAgaing] = useState('');
  const [errorCode, setErrorCode] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // Loading state for button
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate('/login');
      }
    });
    return unsubscribe;
  }, [navigate]);

  const validateForm = () => {
    // Check if the new password is empty or too short
    if (!newPW || newPW.length < 6) {
      setErrorMessage('Nýtt lykilorð verður að vera í það minnsta 6 stafa langt.');
      return false;
    }

    // Check if the new password and confirmation match
    if (newPW !== newPWAgaing) {
      setErrorMessage('"Nýtt lykilorð" og "Nýtt lykilorð aftur" eru ekki eins.');
      return false;
    }

    return true;
  };

  const changePassword = async (e) => {
    e.preventDefault();

    // Prevent further submissions during processing
    if (isSubmitting) return;

    // Validate the form before proceeding
    if (!validateForm()) {
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      setErrorMessage('No user is currently logged in.');
      return;
    }

    setIsSubmitting(true); // Disable the submit button while processing

    try {
      // Re-authenticate the user with current credentials
      const credential = EmailAuthProvider.credential(user.email, oldPW);
      await reauthenticateWithCredential(user, credential);
      console.log('User re-authenticated successfully');

      // Update the password after re-authentication
      await updatePassword(user, newPW);
      console.log('Password updated successfully');
      window.alert(
        'Lykilorði hefur nú verið breytt. Aðgangurinn þinn verður skráður út og þú getur nú skráð þig inn afur með nýju lykilorði.'
      );

      // Sign out the user and redirect to home
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error in password update process:', error);
      if (error.code === 'auth/requires-recent-login') {
        setErrorMessage('You need to sign in again to change your password.');
      } else {
        setErrorCode(error.code);
      }
    } finally {
      setIsSubmitting(false); // Re-enable the submit button after processing
    }
  };

  useEffect(() => {
    if (errorCode) {
      switch (errorCode) {
        case 'auth/weak-password':
          setErrorMessage('Lykilorð verður að vera í það minnsta 6 stafa langt.');
          break;
        case 'auth/wrong-password':
        case 'auth/invalid-email':
          setErrorMessage('Ekki rétt netfang eða lykilorð.');
          break;
        default:
          setErrorMessage('Villa kom upp, vinsamlegast reynið aftur.');
          break;
      }
    }
  }, [errorCode]);

  const passwordCB = (who, value) => {
    if (who === 'nuverandiLykilord') {
      setOldPW(value);
    } else if (who === 'nyttLykilord') {
      setNewPW(value);
    } else if (who === 'nyttLykilordAftur') {
      setNewPWAgaing(value);
    }
  };

  return (
    <div className='changePassword col col-4 offset-col-4 col-md-8 offset-col-md-2'>
      <h2>Breyta lykilorði</h2>
      <p className='incorrect'>{errorMessage}</p>
      <form onSubmit={changePassword}>
        <label htmlFor='email'>Netfang</label>
        <input
          id='email'
          type='email'
          placeholder='Netfang'
          onChange={(e) => {
            setEmail(e.target.value);
          }}></input>
        <PasswordInput
          text='Núverandi lykilorð'
          htmlFor='nuverandiLykilord'
          passwordCB={passwordCB}
          placeholder='Núverandi lykilorð'
        />
        <PasswordInput
          text='Nýtt lykilorð'
          htmlFor='nyttLykilord'
          passwordCB={passwordCB}
          placeholder='Nýtt lykilorð'
        />
        <PasswordInput
          text='Nýtt lykilorð aftur'
          htmlFor='nyttLykilordAftur'
          passwordCB={passwordCB}
          placeholder='Nýtt lykilorð aftur'
        />
        <Button type="submit" disabled={isSubmitting} children='Breyta' />
      </form>
    </div>
  );
}
