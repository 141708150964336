import React, { Component } from 'react';
import { auth } from '../../firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'; // Import sendPasswordResetEmail
import { useNavigate } from 'react-router-dom';

// Sass
import './Login.scss';

// Components
import Button from '../../components/button';
import PasswordInput from '../../components/passwordInput/PasswordInput.jsx';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      loginErr: false,
      resetEmailSent: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.passwordCB = this.passwordCB.bind(this);
    this.handlePasswordReset = this.handlePasswordReset.bind(this); // Bind the new method
  }

  handleChange(event) {
    if (event.target.className === 'login__email') {
      this.setState({ email: event.target.value });
    } else if (event.target.className === 'login__password') {
      this.setState({ password: event.target.value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const email = this.state.email;
    const pass = this.state.password;
    signInWithEmailAndPassword(auth, email, pass)
      .then((cred) => {
        this.props.navigate('/');
      })
      .catch((error) => {
        console.log('Error signing in:', error);
        this.setState({ loginErr: true });
      });
  }

  handlePasswordReset(e) {
    e.preventDefault();
    const email = this.state.email;
    if (!email) {
      alert('Please enter your email address');
      return;
    }
    sendPasswordResetEmail(auth, email)
      .then(() => {
        this.setState({ resetEmailSent: true });
      })
      .catch((error) => {
        console.error('Error sending password reset email:', error);
      });
  }

  passwordCB(who, value) {
    this.setState({ password: value });
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.props.navigate('/');
      }
    });
  }

  render() {
    const { loginErr, resetEmailSent } = this.state;
    let villa;
    if (loginErr) {
      villa = (
        <p className='login__error incorrect' data-testid='error'>
          Netfang og/eða lykilorð er ekki rétt slegið inn
        </p>
      );
    }

    let resetMessage;
    if (resetEmailSent) {
      resetMessage = (
        <p className='login__success' data-testid='reset-success'>
          Ef vefpósturinn er skráður hjá okkur muntu fá póst sendan á næstu 5 mínútum til að endurræsa lykilorðið þitt.
        </p>
      );
    }

    return (
      <div
        className='auth col col-4 offset-col-4 col-md-8 offset-col-md-2'
        data-testid='login'>
        <h2>Innskráning</h2>
        <div className='login'>
          <form>
            <label htmlFor='email'>Netfang</label>
            <input
              id='email'
              placeholder='Vefpóstur'
              className='login__email'
              type='email'
              onChange={this.handleChange}
              data-testid='login__email'></input>
            <PasswordInput
              text='Lykilorð'
              htmlFor='lykilord'
              passwordCB={this.passwordCB}
              placeholder='Lykilorð'
            />
            <div className='login__buttons'>
              <Button
                className='login__button'
                onClick={this.handleSubmit}
                children='Skrá inn'
                dataTestid='login__submit'></Button>
              <Button
                className='login__reset-button'
                onClick={this.handlePasswordReset}
                children='Gleymt lykilorð?'
                dataTestid='reset-password'></Button>
            </div>
          </form>
        </div>
        {villa}
        {resetMessage}
      </div>
    );
  }
}

export default function(props) {
  const navigate = useNavigate();
  return <Login {...props} navigate={navigate} />;
}
