import React, { useState, useEffect } from 'react';

// Sass
import './Modal.scss';

// Assets
import close from '../../assets/img/close.svg';

// Components and functions
import { lita } from '../../assets/js/functions';

export default function Modal(props) {
  const [aldursbilChecked, setAldursbilChecked] = useState(false);
  const [rimChecked, setRimChecked] = useState(false);
  const [samstofurChecked, setSamstofurChecked] = useState(false);
  const [samsettChecked, setSamsettChecked] = useState(false);
  const [hljodgreiningChecked, setHljodgreiningChecked] = useState(false);
  const [margraedChecked, setMargraedChecked] = useState(false);
  const [ordhlutaeydingChecked, setOrdhlutaeydingChecked] = useState(false);
  const [hljodtengingChecked, setHljodtengingChecked] = useState(false);
  const [heildChecked, setHeildChecked] = useState(false);

  useEffect(() => {
    const tab = props.tab;

    if (tab === 'aldursbil') {
      setAldursbilChecked(true);
    } else if (tab === 'rim') {
      setRimChecked(true);
    } else if (tab === 'samstofur') {
      setSamstofurChecked(true);
    } else if (tab === 'samsett') {
      setSamsettChecked(true);
    } else if (tab === 'hljodgreining') {
      setHljodgreiningChecked(true);
    } else if (tab === 'margraed') {
      setMargraedChecked(true);
    } else if (tab === 'ordhlutaeyding') {
      setOrdhlutaeydingChecked(true);
    } else if (tab === 'hjodtenging') {
      setHljodtengingChecked(true);
    } else if (tab === 'heild') {
      setHeildChecked(true);
    }
  }, [props]);

  // Faldir radiotakkar til að geta skipt á milli tabs
  const aldursbilRadio = (
    <input
      type='radio'
      id='tabaldursbil'
      name='mytabs'
      checked={aldursbilChecked}
      onChange={() => {
        handleChange('aldursbil');
      }}
    />
  );
  const rimRadio = (
    <input
      type='radio'
      id='tabrim'
      name='mytabs'
      onChange={() => {
        handleChange('rim');
      }}
      checked={rimChecked}
    />
  );
  const samstofurRadio = (
    <input
      type='radio'
      id='tabsamstofur'
      name='mytabs'
      onChange={() => {
        handleChange('samstofur');
      }}
      checked={samstofurChecked}
    />
  );
  const samsettRadio = (
    <input
      type='radio'
      id='tabsamsett'
      name='mytabs'
      onChange={() => {
        handleChange('samsett');
      }}
      checked={samsettChecked}
    />
  );
  const hljodgreiningRadio = (
    <input
      type='radio'
      id='tabhljodgreining'
      name='mytabs'
      onChange={() => {
        handleChange('hljodgreining');
      }}
      checked={hljodgreiningChecked}
    />
  );
  const margraedRadio = (
    <input
      type='radio'
      id='tabmargraed'
      name='mytabs'
      onChange={() => {
        handleChange('margraed');
      }}
      checked={margraedChecked}
    />
  );
  const ordhlutaeydingRadio = (
    <input
      type='radio'
      id='tabordhlutaeyding'
      name='mytabs'
      onChange={() => {
        handleChange('ordhlutaeyding');
      }}
      checked={ordhlutaeydingChecked}
    />
  );
  const hljodtengingRadio = (
    <input
      type='radio'
      id='tabhljodtenging'
      name='mytabs'
      onChange={() => {
        handleChange('hljodtenging');
      }}
      checked={hljodtengingChecked}
    />
  );
  const heildRadio = (
    <input
      type='radio'
      id='tabheild'
      name='mytabs'
      onChange={() => {
        handleChange('heild');
      }}
      checked={heildChecked}
    />
  );

  const aldursbilArray = [1, 2, 3, 4];
  const rimStig = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const samstofurStig = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  const samsettStig = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const hljodgreiningStig = [0, 3, 6, 9, 12, 15];
  const margraedStig = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  const ordhlutaeydingStig = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const hljodtengingStig = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  const heildStig = [];
  for (let i = 0; i < 72; i++) {
    heildStig[i] = i;
  }

  const tableRim = rimStig.map((stig, j) => {
    return (
      <tr key={j}>
        {aldursbilArray.map((aldursbil, i) => {
          return (
            <td key={i} className={lita(1, aldursbil, stig)}>
              {stig}
            </td>
          );
        })}
      </tr>
    );
  });

  const tableSamstofur = samstofurStig.map((stig, j) => {
    return (
      <tr key={j}>
        {aldursbilArray.map((aldursbil, i) => {
          return (
            <td key={i} className={lita(2, aldursbil, stig)}>
              {stig}
            </td>
          );
        })}
      </tr>
    );
  });

  const tableSamsett = samsettStig.map((stig, j) => {
    return (
      <tr key={j}>
        {aldursbilArray.map((aldursbil, i) => {
          return (
            <td key={i} className={lita(3, aldursbil, stig)}>
              {stig}
            </td>
          );
        })}
      </tr>
    );
  });

  const tableHljodgreining = hljodgreiningStig.map((stig, j) => {
    return (
      <tr key={j}>
        {aldursbilArray.map((aldursbil, i) => {
          return (
            <td key={i} className={lita(4, aldursbil, stig)}>
              {stig}
            </td>
          );
        })}
      </tr>
    );
  });

  const tableMargraed = margraedStig.map((stig, j) => {
    return (
      <tr key={j}>
        {aldursbilArray.map((aldursbil, i) => {
          return (
            <td key={i} className={lita(5, aldursbil, stig)}>
              {stig}
            </td>
          );
        })}
      </tr>
    );
  });

  const tableOrdhlutaeyding = ordhlutaeydingStig.map((stig, j) => {
    return (
      <tr key={j}>
        {aldursbilArray.map((aldursbil, i) => {
          return (
            <td key={i} className={lita(6, aldursbil, stig)}>
              {stig}
            </td>
          );
        })}
      </tr>
    );
  });

  const tableHljodtenging = hljodtengingStig.map((stig, j) => {
    return (
      <tr key={j}>
        {aldursbilArray.map((aldursbil, i) => {
          return (
            <td key={i} className={lita(7, aldursbil, stig)}>
              {stig}
            </td>
          );
        })}
      </tr>
    );
  });

  const tableHeild = heildStig.map((stig, j) => {
    return (
      <tr key={j}>
        {aldursbilArray.map((aldursbil, i) => {
          return (
            <td key={i} className={lita('heild', aldursbil, stig)}>
              {stig}
            </td>
          );
        })}
      </tr>
    );
  });

  // Sér um að skipta á milli tabs í modal
  const handleChange = (tab) => {
    // Aftékka alla tab
    setAldursbilChecked(false);
    setRimChecked(false);
    setSamstofurChecked(false);
    setSamsettChecked(false);
    setHljodgreiningChecked(false);
    setMargraedChecked(false);
    setOrdhlutaeydingChecked(false);
    setHljodtengingChecked(false);
    setHeildChecked(false);

    // Tékka bara í réttan tab
    if (tab === 'aldursbil') {
      setAldursbilChecked(true);
    } else if (tab === 'rim') {
      setRimChecked(true);
    } else if (tab === 'samstofur') {
      setSamstofurChecked(true);
    } else if (tab === 'samsett') {
      setSamsettChecked(true);
    } else if (tab === 'hljodgreining') {
      setHljodgreiningChecked(true);
    } else if (tab === 'margraed') {
      setMargraedChecked(true);
    } else if (tab === 'ordhlutaeyding') {
      setOrdhlutaeydingChecked(true);
    } else if (tab === 'hljodtenging') {
      setHljodtengingChecked(true);
    } else if (tab === 'heild') {
      setHeildChecked(true);
    }
  };

  return (
    <div className='modal'>
      <div className='mytabs'>
        {aldursbilRadio}
        <label htmlFor='tabaldursbil'>Færni</label>
        <div className='tab'>
          <h2>Færniflokkar</h2>
          <p>
            Færni nemanda er sett í einn af fjórum færniflokkum og litakóðuð.
            Færniflokkarnir eru, mjög slök færni, slök færni, meðalfærni og góð
            færni. Fylgni er á milli þess að nemendur hafi færni undir
            meðalfærni og þess að hrjást af náms og lestrarörðugleikum í
            grunnskóla.
          </p>
          <p className='skill msf'>Mjög slök færni</p>
          <p className='skill sf'>Slök færni</p>
          <p className='skill mf'>Meðalfærni</p>
          <p className='skill gf'>Góð færni</p>
          <h2>Reikningar á aldursbilum</h2>
          <p>
            Nemendum er skipt niður á aldursbil þar sem mikill þroskamunur getur
            verið á milli nemanda sem eru nær yngri mörkum fyrir prófið og
            nemendum sem eru nær eldri mörkunum. Aldursbilin eru fjögur, hvert
            þeirra spannar um fjóra mánuði og prófið spannar því í heild
            nemendur sem eru fæddir á um 16 mánaða tímabili.
          </p>
          <p className='center'>
            Aldursbil 1 miðast við að nemendur séu 1747-1871 daga gamlir
          </p>
          <p className='center'>
            Aldursbil 2 miðast við að nemendur séu 1872-1991 daga gamlir
          </p>
          <p className='center'>
            Aldursbil 3 miðast við að nemendur séu 1992-2111 daga gamlir
          </p>
          <p className='center'>
            Aldursbil 4 miðast við að nemendur séu 2112-2236 daga gamlir
          </p>
          <p>
            Þeir nemendur sem eru innan við 1747 daga gamlir ná ekki inn á
            aldursbil 1 og teljast því of ungir. Nemendur sem eru eldri en 2236
            daga gamlir eru orðnir of gamlir til að taka prófið.
          </p>
          <p>
            Við útreikninga á útprentaðu prófi er gert ráð fyrir að allir
            mánuðir séu 30 dagar og ekki er tekið tillit til hlaupaára.
            Útreikningurinn hér er því nákvæmari en hafið í huga að ef nemandi
            er nálægt mörkunum á milli aldursbila, þá getur munað nokrum dögum á
            því sem er reiknað hér og því sem reiknað væri handvirkt.
          </p>
        </div>
        {rimRadio}
        <label htmlFor='tabrim'>Rím</label>
        <div className='tab'>
          <h2>Tafla fyrir rím</h2>
          <table>
            <thead>
              <tr>
                <th>Aldursbil 1</th>
                <th>Aldursbil 2</th>
                <th>Aldursbil 3</th>
                <th>Aldursbil 4</th>
              </tr>
            </thead>
            <tbody>{tableRim}</tbody>
          </table>
        </div>
        {samstofurRadio}
        <label htmlFor='tabsamstofur'>Samstöfur</label>
        <div className='tab'>
          <h2>Tafla fyrir samstöfur</h2>
          <table>
            <thead>
              <tr>
                <th>Aldursbil 1</th>
                <th>Aldursbil 2</th>
                <th>Aldursbil 3</th>
                <th>Aldursbil 4</th>
              </tr>
            </thead>
            <tbody>{tableSamstofur}</tbody>
          </table>
        </div>
        {samsettRadio}
        <label htmlFor='tabsamsett'>Samsett orð</label>
        <div className='tab'>
          <h2>Tafla fyrir samsett orð</h2>
          <table>
            <thead>
              <tr>
                <th>Aldursbil 1</th>
                <th>Aldursbil 2</th>
                <th>Aldursbil 3</th>
                <th>Aldursbil 4</th>
              </tr>
            </thead>
            <tbody>{tableSamsett}</tbody>
          </table>
        </div>
        {hljodgreiningRadio}
        <label htmlFor='tabhljodgreining'>Hljóðgreining</label>
        <div className='tab'>
          <h2>Tafla fyrir hljóðgreiningu</h2>
          <table>
            <thead>
              <tr>
                <th>Aldursbil 1</th>
                <th>Aldursbil 2</th>
                <th>Aldursbil 3</th>
                <th>Aldursbil 4</th>
              </tr>
            </thead>
            <tbody>{tableHljodgreining}</tbody>
          </table>
        </div>
        {margraedRadio}
        <label htmlFor='tabmargraed'>Margræð orð</label>
        <div className='tab'>
          <h2>Tafla fyrir margræð orð</h2>
          <table>
            <thead>
              <tr>
                <th>Aldursbil 1</th>
                <th>Aldursbil 2</th>
                <th>Aldursbil 3</th>
                <th>Aldursbil 4</th>
              </tr>
            </thead>
            <tbody>{tableMargraed}</tbody>
          </table>
        </div>
        {ordhlutaeydingRadio}
        <label htmlFor='tabordhlutaeyding'>Orðhlutaeyðing</label>
        <div className='tab'>
          <h2>Tafla fyrir orðhlutaeyðingu</h2>
          <table>
            <thead>
              <tr>
                <th>Aldursbil 1</th>
                <th>Aldursbil 2</th>
                <th>Aldursbil 3</th>
                <th>Aldursbil 4</th>
              </tr>
            </thead>
            <tbody>{tableOrdhlutaeyding}</tbody>
          </table>
        </div>
        {hljodtengingRadio}
        <label htmlFor='tabhljodtenging'>Hljóðtenging</label>
        <div className='tab'>
          <h2>Tafla fyrir hljóðtengingu</h2>
          <table>
            <thead>
              <tr>
                <th>Aldursbil 1</th>
                <th>Aldursbil 2</th>
                <th>Aldursbil 3</th>
                <th>Aldursbil 4</th>
              </tr>
            </thead>
            <tbody>{tableHljodtenging}</tbody>
          </table>
        </div>
        {heildRadio}
        <label htmlFor='tabheild'>Heild</label>
        <div className='tab'>
          <h2>Tafla fyrir heildarniðurstöður</h2>
          <table>
            <thead>
              <tr>
                <th>Aldursbil 1</th>
                <th>Aldursbil 2</th>
                <th>Aldursbil 3</th>
                <th>Aldursbil 4</th>
              </tr>
            </thead>
            <tbody>{tableHeild}</tbody>
          </table>
        </div>
      </div>
      <img
        src={close}
        className='close'
        alt='X til að loka glugga'
        onClick={() => {
          props.modalCB();
        }}
      />
    </div>
  );
}
