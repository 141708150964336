/*
Functional component sem tekur á móti password og sendir til 
foreldris. Er með auga sem breytir <input /> á milli 'password'
og 'text'.
Þarf að fá þrjú gildi í props:

text: strengur, lýsing í label

htmlFor: Nafn á html element, tengir saman þanni að hægt sé að
  klikka á <label /> til að fókusa á <input/>

passwordCB: Fall sem tekur við tveim gildum, 
  who: Hvaða input er að kalla, svo hægt sé að vera með mörg 
    PasswordInput á síðu.
  value: Það sem er slegið inn í <input/>þ
*/

import React, { useState } from 'react';

// Sass
import './PasswordInput.scss';

// Assets
import eye from '../../assets/img/eye.svg';

export default function PasswordInput(props) {
  const [type, setType] = useState('password');

  const toggleVis = () => {
    if (type === 'password') {
      setType('text');
    } else if (type === 'text') {
      setType('password');
    }
  };

  const handlePasswordChange = (e) => {
    props.passwordCB(props.htmlFor, e.target.value);
  };

  return (
    <div className='passwordInput'>
      <label htmlFor={props.htmlFor}>{props.text}</label>
      <div>
        <input
          type={type}
          id={props.htmlFor}
          name={props.htmlFor}
          onChange={handlePasswordChange}
          placeholder={props.placeholder}
        />
        <img alt='Sjá lykilorð' src={eye} onClick={toggleVis} />
      </div>
    </div>
  );
}
