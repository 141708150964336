import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../../firebase'; // Ensure Firestore is imported correctly

// Sass
import './Skra.scss';

// Component
import Button from '../../components/button';
import SkraForm from '../../components/skraForm/SkraForm';

class Skra extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fjoldi: parseInt(localStorage.getItem('fjoldiIProfi')), // Get 'fjoldiIProfi' from local storage and parse it as an integer
      fjoldaArray: [], // Initialize an empty array for fjoldaArray
      doneNem: parseInt(localStorage.getItem('doneNem')), // Get 'doneNem' from local storage and parse it as an integer
    };

    this.formSubmitCB = this.formSubmitCB.bind(this); // Bind formSubmitCB method
    this.makeFjoldaArray = this.makeFjoldaArray.bind(this); // Bind makeFjoldaArray method
  }

  async componentDidMount() {
    auth.onAuthStateChanged((user) => { // Listen for authentication state changes
      if (user) {
        this.setState({ loggedIn: true }); // Set loggedIn state to true if user is authenticated
        this.makeFjoldaArray(); // Call makeFjoldaArray method
      } else {
        this.props.history.push('/login'); // Redirect to login if user is not authenticated
      }
    });
    if (
      !localStorage.getItem('fjoldiIProfi') || // Check if 'fjoldiIProfi' is not in local storage
      !localStorage.getItem('kennari') // Check if 'kennari' is not in local storage
    ) {
      this.props.history.push('/'); // Redirect to home if either is not present
    }
  }

  makeFjoldaArray() {
    const fjoldi = this.state.fjoldi; // Get fjoldi from state
    const fjoldaArray = []; // Initialize an empty array
    for (let i = 1; i <= fjoldi; i++) { // Loop from 1 to fjoldi
      fjoldaArray[i - 1] = i; // Populate fjoldaArray with numbers from 1 to fjoldi
    }
    this.setState({ fjoldaArray }); // Update state with the new fjoldaArray
  }

  async formSubmitCB(n, gogn, updateDone) {
    const { doneNem } = this.state; // Destructure doneNem from state

    // Set data from form into local storage
    localStorage.setItem('nemandi' + n, gogn); // Store form data in local storage with key 'nemandi' + n

    // Update doneNem in local storage
    if (updateDone) { // Check if updateDone is true
      localStorage.setItem('doneNem', parseInt(doneNem) + 1); // Increment doneNem in local storage
      this.setState({
        doneNem: parseInt(doneNem) + 1, // Update doneNem in state
      });
    }
  }

  render() {
    const { doneNem, fjoldi, fjoldaArray } = this.state; // Destructure state variables

    let content;
    if (fjoldi) { // Check if fjoldi is defined
      content = fjoldaArray.map((i) => { // Map over fjoldaArray
        const nem = localStorage.getItem('nemandi' + i); // Get nemandi data from local storage
        return (
          <div
            className='col col-6 offset-col-3 col-md-11 offset-col-md-0'
            key={i}>
            <SkraForm
              num={i}
              formSubmitCB={this.formSubmitCB}
              nem={nem}></SkraForm> {/* Render SkraForm component */}
          </div>
        );
      });
    }

    let takki;
    if (parseInt(doneNem) !== parseInt(fjoldi) && fjoldi) { // Check if doneNem is not equal to fjoldi and fjoldi is defined
      takki = (
        <div
          className='col col-2 offset-col-5 heildarSub col-md-6 offset-col-md-3'
          key='sub'>
          <Button
            className='button__inactive'
            children='Skoða tölfræði og heildarniðurstöður'></Button>
        </div>
      );
    } else if (parseInt(doneNem) === parseInt(fjoldi) && fjoldi) { // Check if doneNem is equal to fjoldi and fjoldi is defined
      takki = (
        <div
          className='col col-2 offset-col-5 heildarSub col-md-6 offset-col-md-3'
          key='sub'>
          <Link to='/results'>
            <Button children='Skoða tölfræði og heildarniðurstöður'></Button> {/* Render Button with Link to results */}
          </Link>
        </div>
      );
    }

    return (
      <div className='skra row' data-testid='skra'>
        <h2 className='col col-6 offset-col-3 col-md-10 offset-col-md-1'>
          Skráningarform fyrir hvern nemanda
        </h2>
        {content}
        {takki}
      </div>
    );
  }
}
export default Skra;
