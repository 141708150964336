import React, { Component } from 'react';
import { auth, firestore } from '../../firebase.js';  // Adjust the import path if necessary
import { collection, addDoc, doc, updateDoc } from 'firebase/firestore';
import { Chart, registerables } from 'chart.js'; // Import Chart.js and registerables



// Components
import ResultsTafla from '../../components/resultsTafla/ResultsTafla';
import ResultsMedal from '../../components/resultsMedal/ResultsMedal';
import ResultsNemenda from '../../components/resultsNemenda/ResultsNemenda';



// Assets
import { sanitizeAndValidateGogn } from '../../assets/js/functions';

// CSS
import './Results.scss';

// Register all necessary components, including the radialLinear scale
Chart.register(...registerables);

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fjoldi: parseInt(localStorage.getItem('fjoldiIProfi')),
      doneNem: parseInt(localStorage.getItem('doneNem')),
      uid: '',
    };

    this.sendAll = this.sendAll.bind(this);
    this.addToFirebase = this.addToFirebase.bind(this);
    this.updateFirebase = this.updateFirebase.bind(this);
  }

  async componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ loggedIn: true });
        this.sendAll(user.uid);
      } else {
        this.props.history.push('/login');
      }
    });
  }

  async sendAll(uid) {
    const { fjoldi } = this.state;
    for (let i = 0; i < fjoldi; i++) {
      const lsGogn = localStorage.getItem('nemandi' + (i + 1)).split(',');
      const gogn = sanitizeAndValidateGogn(lsGogn, uid);
      if (lsGogn[17] === 'needsSending') {
        if (gogn) {
          await this.addToFirebase(lsGogn, gogn, i);
        }
      } else if (lsGogn[17] === 'needsUpdating') {
        if (gogn) {
          await this.updateFirebase(lsGogn, gogn, i, lsGogn[18]);
        }
      }
    }
  }

  async addToFirebase(lsGogn, gogn, i) {
    try {
    const res = await addDoc(collection(firestore, 'hljom2__skra'), gogn);
    console.log('Sendi inn númer: ' + (i + 1));

    lsGogn[17] = 'sent';
    lsGogn[18] = res.id;
    localStorage.setItem('nemandi' + (i + 1), lsGogn.join(','));
  } catch (err) {
    console.error('Error writing document: ', err);
    this.setState({ error: true });
  }
}

  async updateFirebase(lsGogn, gogn, i, uid) {
  try {
    const docRef = doc(firestore, 'hljom2__skra', uid);
    await updateDoc(docRef, gogn);
    lsGogn[17] = 'sent';
    lsGogn[18] = uid;
    localStorage.setItem('nemandi' + (i + 1), lsGogn.join(','));
  } catch (err) {
    console.error('Error updating document: ', err);
    this.setState({ error: true });
  }
}

  render() {
    const { success, error } = this.state;

    let successMessage;
    if (success) {
      successMessage = (
        <p className='correct message'>
          Sending á niðurstöðum tókst, takk fyrir.
        </p>
      );
    }

    let errorMessage;
    if (error) {
      errorMessage = (
        <p className='incorrect message'>
          Ekki tókst að senda niðurstöður inn til Miðstöðvar menntunar og skólaþjónustu.
          Vinsamlegast reynið aftur.
        </p>
      );
    }

    return (
      <div className='results row'>
        <h2 className='col col-6 offset-col-3 col-md-8 offset-col-md-2'>
          Niðurstöður úr HLJÓM-2 athugun
        </h2>
        {successMessage}
        {errorMessage}
        <ResultsTafla />
        <ResultsMedal aldursbil='1' />
        <ResultsMedal aldursbil='2' />
        <ResultsMedal aldursbil='3' />
        <ResultsMedal aldursbil='4' />
        <ResultsNemenda />
      </div>
    );
  }
}

export default Results;