import React, { useState, useEffect } from 'react';
import { auth } from '../../firebase';
import { useNavigate, useLocation } from 'react-router-dom';

// Sass
import './Header.scss';

// Components
import Button from '../button/Button';

// Assets
import logo from '../../assets/img/mmsLogoCroped.png';
import avatar from '../../assets/img/menu.svg';

export default function Header() {
  const [startDate] = useState(localStorage.getItem('startDate'));
  const [loggedIn, setLoggedIn] = useState(false);
  const [content, setContent] = useState('start');
  const [open, setOpen] = useState(false);
  const [hendaNem, setHendaNem] = useState(false);
  const [hendaKenn, setHendaKenn] = useState(false);
  const [kennari, setKennari] = useState(localStorage.getItem('kennari'));
  const [kennaraKt, setKennaraKt] = useState(localStorage.getItem('kennaraKt'));
  const [leikskoli, setLeikskoli] = useState(localStorage.getItem('leikskoli'));
  const [sveitarfelag, setSveitarfelag] = useState(
    localStorage.getItem('sveitarfelag'),
  );
  const [fjoldi, setFjoldi] = useState(
    parseInt(localStorage.getItem('fjoldiIProfi')),
  );
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setLoggedIn(true);
      } else {
        navigate('/login');
      }
    });

    // Henda gögnum úr LocalStorage ef þau eru orðin meira en 9 mánaða gömul
    if (startDate) {
      const splitDate = startDate.split('-');
      let today = new Date();
      today = today.toISOString().split('T')[0].split('-');
      const yearsBetween = today[0] - splitDate[0];

      if (yearsBetween === 1) {
        const montsBetween = 12 - splitDate[1] + today[1];

        if (montsBetween > 9) {
          clearLS();
          navigate('/');
        }
      } else if (yearsBetween > 1) {
        clearLS();
        navigate('/');
      }
    }
  }, [navigate, startDate]);

  useEffect(() => {
    setKennari(localStorage.getItem('kennari'));
    setKennaraKt(localStorage.getItem('kennaraKt'));
    setLeikskoli(localStorage.getItem('leikskoli'));
    setSveitarfelag(localStorage.getItem('sveitarfelag'));
    setFjoldi(parseInt(localStorage.getItem('fjoldiIProfi')));
  }, [content]);

  const clearLS = () => {
    const tibrr = localStorage.getItem('tibrrCookieConsent');
    localStorage.clear();
    localStorage.setItem('tibrrCookieConsent', tibrr);
  };

  const henda = (e) => {
    e.preventDefault();
    const confirmed = window.confirm(
      'Þessi aðgerð eyðir gögnum varanlega, ertu viss um að vilja halda áfram?',
    );

    if (confirmed) {
      if (hendaNem) {
        const kennariLS = localStorage.getItem('kennari');
        const kennaraKtLS = localStorage.getItem('kennaraKt');
        const leikskoliLS = localStorage.getItem('leikskoli');
        const sveitarfelagLS = localStorage.getItem('sveitarfelag');
        const tibrr = localStorage.getItem('tibrrCookieConsent');
        const startDate = localStorage.getItem('startDate');

        localStorage.clear();

        localStorage.setItem('kennari', kennariLS);
        localStorage.setItem('kennaraKt', kennaraKtLS);
        localStorage.setItem('leikskoli', leikskoliLS);
        localStorage.setItem('sveitarfelag', sveitarfelagLS);
        localStorage.setItem('tibrrCookieConsent', tibrr);
        localStorage.setItem('startDate', startDate);
      }

      if (hendaKenn) {
        localStorage.removeItem('kennari');
        localStorage.removeItem('kennaraKt');
        localStorage.removeItem('leikskoli');
        localStorage.removeItem('sveitarfelag');
      }
      setOpen(false);
      setContent('start');
      setHendaNem(false);
      setHendaKenn(false);
      navigate('/');
    }
  };

  const breyta = (e) => {
    e.preventDefault();

    localStorage.setItem('kennari', kennari);
    localStorage.setItem('kennaraKt', kennaraKt);
    localStorage.setItem('leikskoli', leikskoli);
    localStorage.setItem('sveitarfelag', sveitarfelag);

    setOpen(false);
    setContent('start');
  };

  const breytaFjoldaILS = (e) => {
    e.preventDefault();
    const fjoldiLS = parseInt(localStorage.getItem('fjoldiIProfi'));
    const fjoldiInt = parseInt(fjoldi);
    if (fjoldiLS > fjoldiInt) {
      for (let i = fjoldiLS; i > fjoldiInt; i--) {
        const nemandi = localStorage.getItem(`nemandi${i}`);
        if (nemandi) {
          localStorage.removeItem(`nemandi${i}`);
          localStorage.removeItem(`umsogn${i}`);
          const doneNem = parseInt(localStorage.getItem('doneNem'));
          localStorage.setItem('doneNem', doneNem - 1);
        }
      }
    }

    localStorage.setItem('fjoldiIProfi', fjoldi);
    setOpen(false);
    setContent('start');
    navigate('/');
  };

  const logOut = (e) => {
    const consent = window.confirm(
      'Aðgangurinn verður nú skráður út. Af öryggisástæðum mun útskráning henda öllum skráðum upplýsingum, bæði um kennara og nemendur. Ef niðurstöður hafa verið skoðaðar er afrit af gögnunum komið í vörslu Menntamálastofnunar. Viltu halda áfram?',
    );
    if (consent) {
      clearLS();
      auth.signOut().then(() => {
        setLoggedIn(false);
        setOpen(false);
        navigate('/login');
      });
    }

    setOpen(false);
  };

  let breytaFjolda;
  if (location.pathname === '/skra') {
    breytaFjolda = (
      <>
        <p
          onClick={(e) => {
            setContent('breytaFjolda');
          }}>
          Breyta fjölda nemenda
        </p>
        <hr />
      </>
    );
  }

  let openClass;
  if (open) {
    openClass = 'headerDropDown';
  } else {
    openClass = 'headerDropDownClosed';
  }

  let avatarComponent;
  if (loggedIn) {
    avatarComponent = (
      <img
        className='avatar'
        src={avatar}
        alt='Notendamöguleikar'
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
          setContent('start');
        }}
      />
    );
  } else {
    avatarComponent = <div className='avatarPlaceholder'></div>;
  }

  let contentElement;
  if (content === 'start') {
    contentElement = (
      <div className={openClass}>
        <p
          onClick={() => {
            setOpen(false);
            setContent('start');
            navigate('/info');
          }}>
          Upplýsingar um HLJÓM-2
        </p>
        <hr />
        <p
          onClick={(e) => {
            setContent('hendaProfum');
          }}>
          Henda prófupplýsingum
        </p>
        <hr />
        {breytaFjolda}
        <p
          onClick={(e) => {
            setContent('breytaKennara');
          }}>
          Breyta kennaraupplýsingum
        </p>
        <hr />
        <p
          onClick={() => {
            navigate('/changepassword');
            setOpen(false);
          }}>
          Breyta lykilorði
        </p>
        <hr />
        <p onClick={logOut}>Skrá út</p>
      </div>
    );
  } else if (content === 'hendaProfum') {
    contentElement = (
      <div className={openClass}>
        <form className='header__henda'>
          <div>
            <input
              type='checkbox'
              id='nem'
              onChange={(e) => {
                setHendaNem(e.target.checked);
              }}
            />
            <label htmlFor='nem'>Henda prófupplýsingum</label>
          </div>
          <div>
            <input
              type='checkbox'
              id='kenn'
              onChange={(e) => {
                setHendaKenn(e.target.checked);
              }}
            />
            <label htmlFor='kenn'>Henda kennaraupplýsingum</label>
          </div>
          <div className='henda__button'>
            <Button
              className='button__danger'
              children='Henda'
              onClick={henda}
            />
          </div>
        </form>
      </div>
    );
  } else if (content === 'breytaKennara') {
    contentElement = (
      <div className={openClass}>
        <form className='header__breyta'>
          <label htmlFor='nafn'>Nafn</label>
          <input
            type='text'
            id='nafn'
            value={kennari}
            onChange={(e) => {
              setKennari(e.target.value);
            }}
          />
          <label htmlFor='kennararKennitala'>Kennitala</label>
          <input
            type='text'
            id='kennararKennitala'
            value={kennaraKt}
            onChange={(e) => {
              setKennaraKt(e.target.value);
            }}
          />
          <label htmlFor='leikskoli'>Leikskóli</label>
          <input
            type='text'
            id='leikskoli'
            value={leikskoli}
            onChange={(e) => {
              setLeikskoli(e.target.value);
            }}
          />
          <label htmlFor='sveitarfelag'>Sveitarfélag</label>
          <input
            type='text'
            id='sveitarfelag'
            value={sveitarfelag}
            onChange={(e) => {
              setSveitarfelag(e.target.value);
            }}
          />
          <Button children='Breyta' onClick={breyta} />
        </form>
      </div>
    );
  } else if (content === 'breytaFjolda') {
    contentElement = (
      <div className={openClass}>
        <form className='header__breyta'>
          <label htmlFor='fjoldi'>Nýr fjöldi nemenda</label>
          <input
            type='number'
            name='fjoldi'
            id='fjoldi'
            value={fjoldi}
            onChange={(e) => {
              setFjoldi(e.target.value);
            }}
          />
          <Button children='Breyta' onClick={breytaFjoldaILS} />
        </form>
      </div>
    );
  }

  return (
    <div className='wrapper'>
      {contentElement}

      <div className='headerDiv'>
        <div className='header'>
          <img
            className='menntaLogo'
            alt='Merki Menntamálastofnunar'
            src={logo}
            onClick={() => {
              navigate('/');
            }}
          />
          <h1>Innsláttargátt fyrir HLJÓM-2</h1>
          {avatarComponent}
        </div>
      </div>
    </div>
  );
}
