import React, { Component } from 'react';
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
import * as Excel from 'exceljs';
import { saveAs } from 'file-saver';

// Sass
import './ResultsTafla.scss';

// Components
import Button from '../button/Button';

// Assets
import { lita } from '../../assets/js/functions';
import arrowup from '../../assets/img/arrow-up.svg';
import arrowdown from '../../assets/img/arrow-down.svg';
import excelIcon from '../../assets/img/excel-icon.svg';
import pdfIcon from '../../assets/img/pdf-icon.svg';
import getMMSLogo from './logo';

class ResultsTafla extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrowupClass: 'hide',
      arrowdownClass: '',
      fjoldiIProfi: localStorage.getItem('fjoldiIProfi'),
      fjoldiArray: [],
      nemendaData: [],
      csv: [],
    };

    this.open = this.open.bind(this);
    this.makePDF = this.makePDF.bind(this);
    this.makeExcel = this.makeExcel.bind(this);
    this.insertLinurToPDF = this.insertLinurToPDF.bind(this);
  }

  componentDidMount() {
    const fjoldiIProfi = parseInt(this.state.fjoldiIProfi);

    const fjoldiArray = [];
    for (let i = 0; i < fjoldiIProfi; i++) {
      fjoldiArray[i] = i + 1;
    }

    let nemendaData = [];
    nemendaData = fjoldiArray.map((i) => {
      let nemandiI = localStorage.getItem(`nemandi${i}`);
      if (nemandiI) {
        return nemandiI.split(',');
      } else return null;
    });

    const logo = getMMSLogo();

    this.setState({ fjoldiArray, nemendaData, logo });
  }

  // Opnar og lokar hverju formi fyrir sig
  open() {
    if (this.state.open) {
      this.setState({ open: false, arrowdownClass: '', arrowupClass: 'hide' });
    } else if (!this.state.open) {
      this.setState({ open: true, arrowdownClass: 'hide', arrowupClass: '' });
    }
  }

  async makeExcel() {
    // Búa til Excel skjal
    const { nemendaData } = this.state;
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('sheet 1');

    // Búa til header á síðu
    const header = [
      'Nafn',
      'Kennitala',
      'Annað mál',
      'Kyn',
      'Prófdagur',
      'Aldur í dögum',
      'Aldursbil',
      'Rím',
      'Samstöfur',
      'Samsett orð',
      'Hljóðgreining',
      'Margræð orð',
      'Orðhlutaeyðing',
      'Hljóðtenging',
      'Samtals',
      'Færni',
    ];
    sheet.addRow(header);

    // Setja nemendaupplýsingar á síðu
    nemendaData.map((prof) => {
      sheet.addRow([
        `${prof[1]}`,
        `${prof[2]}`,
        `${prof[3]}`,
        `${prof[4]}`,
        `${prof[5]}`,
        `${prof[6]}`,
        `${prof[7]}`,
        `${prof[8]}`,
        `${prof[9]}`,
        `${prof[10]}`,
        `${prof[11]}`,
        `${prof[12]}`,
        `${prof[13]}`,
        `${prof[14]}`,
        `${prof[15]}`,
        `${prof[16]}`,
      ]);
      return null;
    });

    // Lita viðeigandi reiti
    sheet.eachRow((row, rowNumber) => {
      const aldursbil = parseInt(row.getCell(7));

      row.eachCell((cell, colNumber) => {
        // Passa að við erum ekki í header línu
        if (parseInt(aldursbil)) {
          // Passa að við séum bara að lita viðeigandi reiti
          if (colNumber < 16 && colNumber > 7) {
            let litur;
            // Fá css lit á reiti, msf, sf, mf ,gf, neutral
            if (colNumber === 15) {
              litur = lita(
                'heild',
                aldursbil,
                parseInt(row.getCell(colNumber).value)
              );
            } else {
              litur = lita(
                colNumber - 7,
                aldursbil,
                parseInt(row.getCell(colNumber).value)
              );
            }

            // Breyta css lit yfir í hex lit
            if (litur === 'msf') {
              litur = 'F8A4A4';
            } else if (litur === 'sf') {
              litur = 'D1CFCF';
            } else if (litur === 'mf') {
              litur = '9FCB9F';
            } else if (litur === 'gf') {
              litur = 'B0B0EE';
            } else {
              litur = 'FFFFFF';
            }

            // Að lokum, lita reitinn með útreiknuðum lit
            row.getCell(colNumber).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: litur,
              },
            };
          }
        }
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';

    const blob = new Blob([buffer], { type: fileType });

    saveAs(blob, 'nidurstodurUrHljom2' + fileExtension);
  }

  async makePDF() {
    const { logo } = this.state;

    const doc = new jsPDF('l', 'mm', 'a4');
    // doc.addPage('a4', 'landscape');
    // Setja inn logo og fyrirsögn
    doc.setFontSize(12);
    doc.addImage(logo, 230, 20, 310 / 6.5, 112 / 6.5);
    doc.text(15, 50, 'Niðurstöður úr HLJÓM-2');

    // Setja inn dagsetningu
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    today = dd + '/' + mm + '/' + yyyy;
    doc.text(15, 35, today);

    // Setja inn töflu header
    const nemHeader = document.querySelector('.nemTaflaHeader');
    const preW = nemHeader.offsetWidth;
    const preML = nemHeader.marginLeft;
    const preMT = nemHeader.marginTop;
    nemHeader.style = 'width: 1230px; margin-left: 0; margin-top: 3em';
    const w = nemHeader.offsetWidth;
    const h = nemHeader.offsetHeight;

    if (doc) {
      domtoimage.toPng(nemHeader).then((imgData) => {
        doc.addImage(imgData, 'PNG', 22, 60, w / 5, h / 5);
        nemHeader.style = `width: ${preW}; margin-left: ${preML}; margin-top: ${preMT};`;
        //doc.save('hljom2-nidurstodutafla.pdf');
      });
    }

    // Setja inn stakar línur úr töflu
    await this.insertLinurToPDF(doc);
    doc.save('hljom2-nidurstodutafla.pdf');
  }

  async insertLinurToPDF(doc) {
    const nemLinur = document.querySelectorAll('.nemandaLina');

    for (let i = 0; i < nemLinur.length; i++) {
      const preWx = nemLinur[i].offsetWidth;
      const preMLx = nemLinur[i].marginLeft;
      const preMTx = nemLinur[i].marginTop;
      nemLinur[i].style = 'width: 1250px; margin-left: 0; margin-top: 0';
      const wx = nemLinur[i].offsetWidth;
      const hx = nemLinur[i].offsetHeight;
      let j = i;
      const imgData = await domtoimage.toPng(nemLinur[i]);

      if (i === 11 || i === 27 || i === 43 || i === 59) {
        doc.addPage('a4', 'landscape');
      }

      if (i > 10 && i < 27) {
        // Færa upp á topp á síðu 2
        j = i - 16;
      } else if (i > 26 && i < 43) {
        // Færa upp á topp á síðu 3
        j = i - 32;
      } else if (i > 42 && i < 59) {
        // Færa upp á topp á síðu 4
        j = i - 48;
      } else if (i > 58) {
        // Færa upp á topp á síðu 5
        j = i - 64;
      }

      doc.addImage(imgData, 'PNG', 22, 70 + 12 * j, wx / 5, hx / 5);
      nemLinur[
        i
      ].style = `width: ${preWx}; margin-left: ${preMLx}; margin-top: ${preMTx};`;
    }
  }

  render() {
    const { arrowupClass, arrowdownClass, fjoldiArray } = this.state;

    let tableContent = fjoldiArray.map((i) => {
      const nem = localStorage.getItem('nemandi' + i).split(',');
      return (
        <tr key={i} className={`nemandaLina`}>
          <td>{nem[1]}</td>
          <td className={nem[16]}>{nem[15]}</td>
          <td className='neutral'>{nem[7]}</td>
          <td className={lita(1, parseInt(nem[7]), nem[8])}>{nem[8]}</td>
          <td className={lita(2, parseInt(nem[7]), nem[9])}>{nem[9]}</td>
          <td className={lita(3, parseInt(nem[7]), nem[10])}>{nem[10]}</td>
          <td className={lita(4, parseInt(nem[7]), nem[11])}>{nem[11]}</td>
          <td className={lita(5, parseInt(nem[7]), nem[12])}>{nem[12]}</td>
          <td className={lita(6, parseInt(nem[7]), nem[13])}>{nem[13]}</td>
          <td className={lita(7, parseInt(nem[7]), nem[14])}>{nem[14]}</td>
        </tr>
      );
    });

    return (
      <div className='resultsTafla col col-10 offset-col-1 col-md-12 offset-col-md-0'>
        <div>
          <div>
            <div className='myndaDiv'>
              <img
                className={arrowupClass}
                onClick={this.open}
                src={arrowup}
                alt='Ör upp'></img>
              <img
                className={arrowdownClass}
                onClick={this.open}
                src={arrowdown}
                alt='Ör niður'></img>
            </div>
          </div>
          <div className='textaDiv'>
            <p className='neutral'>Tafla</p>
          </div>
        </div>
        <div className={`resultTaflaDiv${arrowupClass}`}>
          <table className={`nidurstoduTafla ${arrowupClass}`}>
            <thead>
              <tr className='nemTaflaHeader'>
                <th>Nafn</th>
                <th>Samtals</th>
                <th>Aldursbil</th>
                <th>Rím</th>
                <th>Samstöfur</th>
                <th>Samsett orð</th>
                <th>Hljóðgreining</th>
                <th>Margræð orð</th>
                <th>Orðhlutaeyðing</th>
                <th>Hljóðtenging</th>
              </tr>
            </thead>
            <tbody>{tableContent}</tbody>
          </table>
        </div>
        <div className={`downloadDiv ${arrowupClass}`}>
          <p>
            Athugið að þessi tafla flokkast sem viðkvæmar persónuupplýsingar og
            hana má ekki afhenda eða sýna foreldrum.
          </p>
          <div>
            <div>
              <img
                className='excelIcon'
                src={excelIcon}
                alt='Merki fyrir excel'
              />

              <Button
                children='Sækja töflu á .xlsx formi (2 sek)'
                onClick={this.makeExcel}
              />
            </div>
            <div>
              <img className='pdfIcon' src={pdfIcon} alt='Merki fyrir PDF' />
              <Button
                onClick={this.makePDF}
                children='Sækja töflu á .pdf formi (10 sek)'
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResultsTafla;
