import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';

// Components
import Kennari from '../../components/kennari/Kennari';
import NumStudents from '../../components/numStudents/NumStudents.jsx';

// Sass
import './Start.scss';

export default function Start() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [kennari, setKennari] = useState(false);
  const [fjoldi, setFjoldi] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setLoggedIn(true);
      } else {
        navigate('/login');
      }
    });
    const kennari = localStorage.getItem('kennari');
    const fjoldi = localStorage.getItem('fjoldiIProfi');
    if (kennari && kennari !== 'null') {
      setKennari(true);
    }
    if (fjoldi) {
      setFjoldi(true);
    }
    return unsubscribe;
  }, [navigate]);

  const kennaraCB = (kennari, kennitala, leikskoli, sveitarfelag) => {
    localStorage.setItem('kennari', kennari);
    localStorage.setItem('kennaraKt', kennitala);
    localStorage.setItem('leikskoli', leikskoli);
    localStorage.setItem('sveitarfelag', sveitarfelag);
    setKennari(true);
  };

  const numStudentsCB = (num) => {
    navigate('/skra');
  };

  let comp;
  if (!kennari) {
    comp = <Kennari kennaraCB={kennaraCB} />;
  } else if (kennari && !fjoldi) {
    comp = <NumStudents numStudentsCB={numStudentsCB} />;
  } else if (kennari && fjoldi) {
    comp = <Navigate to='/skra' />;
  }

  if (!loggedIn) {
    return (
      <div className='start col col-4 offset-col-4 col-md-8 offset-col-md-2'>
        <p>Hér þarf að vera skráður inn til að skoða.</p>
      </div>
    );
  }

  return (
    <div
      className='start col col-6 offset-col-3 col-md-8 offset-col-md-2'
      data-testid='start'>
      {comp}
    </div>
  );
}
