import validator from 'validator';
import xss from 'xss';

/* Föll fyrir SkraForm.js, component inni í /skra */
import {
  rimTafla,
  samstofuTafla,
  samsettTafla,
  hljodgreiningarTafla,
  margraedTafla,
  ordhlutaeydingarTafla,
  hljodtengingarTafla,
  heildartafla,
} from './toflur';

// Athugar hvort að x sé gild kennitala.
// Tekur  : Streng af tölum.
// Skilar : true/false.
export function validateKennitala(x) {
  if (x.length !== 10) {
    return false;
  } else if (x[9] === '9' || x[9] === '0') {
    const sum =
      x[0] * 3 +
      x[1] * 2 +
      x[2] * 7 +
      x[3] * 6 +
      x[4] * 5 +
      x[5] * 4 +
      x[6] * 3 +
      x[7] * 2;
    const modulus = sum % 11;
    let vartala;
    if (modulus === 0) {
      vartala = 0;
    } else {
      vartala = 11 - (sum % 11);
    }
    if (vartala === Number(x[8])) {
      return true;
    }
  } else {
    return false;
  }
}

// Aðeins kallað í ef bæði kennitala og prófdagur hafa verið
// slegin inn.
// Tekur  : Valid kennitölu og dagsetningu
// Skilar : Tölu (0-5) sem samsvarar aldursbili og
//          tölu sem samsvarar aldri nemanda í dögum
export function calcAldursbil(kt, dags) {
  const birthday = setAfmaelisdagur(kt);
  const aldurMS = new Date(dags[0], dags[1] - 1, dags[2]) - birthday;
  const aldurDagar = aldurMS / 1000 / 60 / 60 / 24;
  const aldursbil = setAldursbil(aldurDagar);
  return { aldursbil, aldurDagar };
}

// Tekur : Gilda kennitölu
// Skilar: Afmælisdegi sem date
function setAfmaelisdagur(x) {
  let year;
  if (x[9] === '9') {
    year = '19' + x.substring(4, 6);
  } else {
    year = '20' + x.substring(4, 6);
  }
  const month = x.substring(2, 4);
  const day = x.substring(0, 2);

  return new Date(year, month - 1, day);
}

// Tekur : Tölu sem stendur fyrir aldur í dögum
// Skilar: Tölu sem stendur fyrir aldursbil (1-4) eða 0 eða 5
function setAldursbil(aldurDagar) {
  if (aldurDagar < 1747) {
    return 0;
  } else if (aldurDagar < 1872) {
    return 1;
  } else if (aldurDagar < 1992) {
    return 2;
  } else if (aldurDagar < 2112) {
    return 3;
  } else if (aldurDagar < 2237) {
    return 4;
  } else {
    return 5;
  }
}

// Tekur  : Tölu sem stendur fyrir aldursbil
//          Tölu sem stendur fyrir aldur barns í dögum
// Skilar : Texta sem tjáir notanda hve gamalt barnið er og
//          hvaða aldursbil það fellur undir
export function getAldursbilText(aldursbil, aldurDagar) {
  // const remainder = aldurDagar % 365;
  // const ar = (aldurDagar - remainder) / 365;
  // const man = (remainder - (remainder % 30)) / 30;
  // const dagar = remainder % 30;
  let aldursbilText;

  if (aldursbil === 0) {
    aldursbilText = 'er of ungur til að þreyta HLJÓM-2.';
  } else if (aldursbil === 1) {
    aldursbilText = 'fellur undir aldursbil 1';
  } else if (aldursbil === 2) {
    aldursbilText = 'fellur undir aldursbil 2';
  } else if (aldursbil === 3) {
    aldursbilText = 'fellur undir aldursbil 3';
  } else if (aldursbil === 4) {
    aldursbilText = 'fellur undir aldursbil 4';
  } else if (aldursbil === 5) {
    aldursbilText = 'er of gamall til að þreyta HLJÓM-2.';
  }

  //const returnText = `Nemandi ${aldursbilText} (${ar} ára, ${man} mánaða og ${dagar} daga)`;
  const returnText = `Nemandi ${aldursbilText} (${aldurDagar} daga gamall)`;
  return returnText;
}

// Tekur  : Tölu (1-7) eða "heild" sem táknar hvaða hluta prófsins
//          á að fletta upp og lita.
//          Tölu (1-4) sem samsvarar aldurbili sem nemandi er á
//          Tölu sem táknar aldur nemanda í dögum
// Skilar : Streng "msf", "sf", "mf", "gf" eða "neutral" sem
//          Má nota sem class sem CSS sér svo um að lita rétt.
export function lita(hluti, bil, stig) {
  var fletta;
  if (hluti === 1) {
    fletta = rimTafla(bil, stig);
  } else if (hluti === 2) {
    fletta = samstofuTafla(bil, stig);
  } else if (hluti === 3) {
    fletta = samsettTafla(bil, stig);
  } else if (hluti === 4) {
    fletta = hljodgreiningarTafla(bil, stig);
  } else if (hluti === 5) {
    fletta = margraedTafla(bil, stig);
  } else if (hluti === 6) {
    fletta = ordhlutaeydingarTafla(bil, stig);
  } else if (hluti === 7) {
    fletta = hljodtengingarTafla(bil, stig);
  } else if (hluti === 'heild') {
    fletta = heildartafla(bil, stig);
  } else {
    console.log('error - lita');
    return 0;
  }

  if (fletta === 'Mjög slök færni') {
    return 'msf';
  } else if (fletta === 'Slök færni') {
    return 'sf';
  } else if (fletta === 'Meðalfærni') {
    return 'mf';
  } else if (fletta === 'Góð færni') {
    return 'gf';
  } else {
    return 'neutral';
  }
}

// Tekur  : Streng sem samsvarar CSS klasa fyrir lit.
// Skilar : Streng sem samsvarar CSS klasa til að birta.
//          Skilastrengur er í þolfalli.
export function aldursbilString(aldursbil) {
  if (aldursbil === 'msf') {
    return 'mjög slakri færni';
  } else if (aldursbil === 'sf') {
    return 'slakri færni';
  } else if (aldursbil === 'mf') {
    return 'meðalfærni';
  } else if (aldursbil === 'gf') {
    return 'góðri færni';
  } else {
    return '';
  }
}

//
export function sanitizeAndValidateGogn(gogn, uid) {
  // skra/prof
  let clean0 = xss(gogn[0]);
  clean0 = validator.escape(clean0);
  const clean0Match = validator.matches(clean0, 'skra|prof');

  // Kennitala
  let clean2 = xss(gogn[2]);
  clean2 = validator.escape(gogn[2]);
  const clean2Match = validator.matches(clean2, '^[0-9]{10}$');

  // Annað mál
  let clean3 = xss(gogn[3]);
  clean3 = validator.escape(gogn[3]);
  const clean3Match = validator.matches(clean3, 'true|false');

  // Prófdagur
  let clean5 = xss(gogn[5]);
  clean5 = validator.escape(gogn[5]);
  const clean5Match = validator.isDate(clean5);

  // Dagagamall
  let clean6 = xss(gogn[6]);
  clean6 = validator.escape(gogn[6]);
  const clean6Match = validator.matches(clean6, '^[0-9]{4}$');

  // Aldursbil
  let clean7 = xss(gogn[7]);
  clean7 = validator.escape(gogn[7]);
  const clean7Match = validator.matches(clean7, '1|2|3|4');

  // Rím
  let clean8 = xss(gogn[8]);
  clean8 = validator.escape(gogn[8]);
  const clean8Match = validator.matches(clean8, '0|1|2|3|4|5|6|7|8|9|10|11|12');

  // Samstöfur
  let clean9 = xss(gogn[9]);
  clean9 = validator.escape(gogn[9]);
  const clean9Match = validator.matches(clean9, '0|1|2|3|4|5|6|7|8');

  // Samsett orð
  let clean10 = xss(gogn[10]);
  clean10 = validator.escape(gogn[10]);
  const clean10Match = validator.matches(clean10, '0|1|2|3|4|5|6|7|8|9|10');

  // Hljóðgreining
  let clean11 = xss(gogn[11]);
  clean11 = validator.escape(gogn[11]);
  const clean11Match = validator.matches(clean11, '0|3|6|9|12|15');

  // Margræð orð
  let clean12 = xss(gogn[12]);
  clean12 = validator.escape(gogn[12]);
  const clean12Match = validator.matches(clean12, '0|1|2|3|4|5|6|7|8');

  // Orðhlutaeyðing
  let clean13 = xss(gogn[13]);
  clean13 = validator.escape(gogn[13]);
  const clean13Match = validator.matches(clean13, '0|1|2|3|4|5|6|7|8|9|10');

  // Hljóðtenging
  let clean14 = xss(gogn[14]);
  clean14 = validator.escape(gogn[14]);
  const clean14Match = validator.matches(clean14, '0|1|2|3|4|5|6|7|8');

  // Samtals
  let clean15 = xss(gogn[15]);
  clean15 = validator.escape(gogn[15]);
  const clean15Match = validator.matches(clean15, '^[0-9]{1,2}$');

  // Færni
  let clean16 = xss(gogn[16]);
  clean16 = validator.escape(gogn[16]);
  const clean16Match = validator.matches(clean16, 'msf|sf|mf|gf');

  // Kennari
  const kennari = localStorage.getItem('kennari');
  let clean17 = xss(kennari);
  clean17 = validator.escape(kennari);

  // KennaraKt
  const kennaraKt = localStorage.getItem('kennaraKt');
  let clean18 = xss(kennaraKt);
  clean18 = validator.escape(kennaraKt);
  const clean18Match = validator.matches(clean18, '^[0-9]{10}$');

  // Leikskoli
  const leikskoli = localStorage.getItem('leikskoli');
  let clean19 = xss(leikskoli);
  clean19 = validator.escape(leikskoli);

  // Sveitarfélag
  const sveitarfelag = localStorage.getItem('sveitarfelag');
  let clean20 = xss(sveitarfelag);
  clean20 = validator.escape(sveitarfelag);
  if (
    clean0Match &&
    clean2Match &&
    clean3Match &&
    clean5Match &&
    clean6Match &&
    clean7Match &&
    clean8Match &&
    clean9Match &&
    clean10Match &&
    clean11Match &&
    clean12Match &&
    clean13Match &&
    clean14Match &&
    clean15Match &&
    clean16Match &&
    clean18Match
  ) {
    return {
      type: clean0,
      kt: clean2,
      annadMal: clean3,
      profdagur: new Date(clean5),
      dagagamall: clean6,
      aldursbil: clean7,
      rim: clean8,
      samstofur: clean9,
      samsett: clean10,
      hljodgreining: clean11,
      margraed: clean12,
      ordhlutaeyding: clean13,
      hljodtenging: clean14,
      total: clean15,
      faerni: clean16,
      kennari: clean17,
      kennaraKt: clean18,
      leikskoli: clean19,
      sveitarfelag: clean20,
      userID: uid,
    };
  } else {
    return false;
  }
}

//This function returns the from and to dates for kennitala
export function getTwoDates(kennitala) {
  let year;
  if (kennitala[9] === '9') {
    year = '19' + kennitala.substring(4, 6);
  } else {
    year = '20' + kennitala.substring(4, 6);
  }
  const month = kennitala.substring(2, 4);
  const day = kennitala.substring(0, 2);

  const birthDate = new Date(year, month - 1, day);

  // Calculate DateFrom and DateTo
  const DateFrom = new Date(birthDate.getTime() + 1747 * 24 * 60 * 60 * 1000);
  const DateTo = new Date(birthDate.getTime() + 2236 * 24 * 60 * 60 * 1000);

  return { DateFrom, DateTo };
}
