import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
//import 'chartjs-plugin-labels';

// Sass
import './ResultEinstaklingsBar.scss';

// Assets
import infoIcon from '../../assets/img/information.svg';
import { lita } from '../../assets/js/functions';

// Global fastar
const msf = [
  [2, 3, 0, 0, 3, 0, 0],
  [4, 4, 1, 0, 3, 1, 0],
  [5, 4, 2, 0, 4, 1, 1],
  [6, 5, 3, 0, 4, 1, 1],
];
const sf = [
  [5, 5, 2, 0, 4, 2, 1],
  [6, 5, 4, 0, 5, 3, 1],
  [8, 6, 5, 0, 5, 4, 2],
  [9, 7, 6, 0, 6, 4, 2],
];
const mf = [
  [10, 8, 7, 3, 7, 7, 4],
  [11, 8, 8, 6, 7, 8, 5],
  [12, 8, 9, 9, 8, 9, 6],
  [12, 8, 9, 12, 8, 9, 7],
];
const gf = [
  [12, 8, 10, 15, 8, 10, 8],
  [12, 8, 10, 15, 8, 10, 8],
  [12, 8, 10, 15, 8, 10, 8],
  [12, 8, 10, 15, 8, 10, 8],
];

export default function ResultEinstaklingsBar(props) {
  const { nemNum, fyrri, seinni } = props;
  const nemandi = localStorage.getItem(`nemandi${nemNum}`)?.split(',');
  const aldursbil = parseInt(nemandi[7]);

  const [data, setData] = useState({
    labels: ['a'],
    datasets: [
      {
        label: '25% nemenda',
        data: [12],
        backgroundColor: 'rgb(255, 99, 132)',
        stack: 'Stack 0',
      },
    ],
  });
  const [options] = useState({
    scales: {
      yAxes: {
        ticks: {
          beginAtZero: true,
        },
      },
    },
  });
  const [barHover, setBarHover] = useState(false);
  const [fyrriData, setFyrriData] = useState(null);
  const [fyrriClass, setFyrriClass] = useState(null);
  const [seinniData, setSeinniData] = useState(null);
  const [seinniClass, setSeinniClass] = useState(null);

  useEffect(() => {
    setBarData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (fyrri) {
      const fyrriA = fyrri.slice(0, 7);
      const fyrriB = fyrri.slice(9, 16);

      setFyrriData(fyrriA);
      setFyrriClass(fyrriB);
    }

    if (seinni) {
      const seinniA = seinni.slice(0, 7);
      const seinniB = seinni.slice(9, 16);

      setSeinniData(seinniA);
      setSeinniClass(seinniB);
    }
  }, [fyrri, seinni]);

  useEffect(() => {
    setBarData();
    // eslint-disable-next-line
  }, [fyrriData, seinniData]);

  const classToColor = (c) => {
    if (c === 'msf') {
      return { fill: 'rgba(241, 74, 74, 0.5)', border: 'rgba(241, 74, 74)' };
    } else if (c === 'sf') {
      return {
        fill: 'rgba(163, 159, 159, 0.5)',
        border: 'rgba(163, 159, 159)',
      };
    } else if (c === 'mf') {
      return {
        fill: 'rgba(63, 151, 63, 0.5)',
        border: 'rgba(63, 151, 63)',
      };
    } else if (c === 'gf') {
      return {
        fill: 'rgba(97, 97, 221, 0.5)',
        border: 'rgba(97, 97, 221)',
      };
    } else {
      return {
        fill: 'rgba(0,0,0, 0.5)',
        border: 'rgba(0,0,0)',
      };
    }
  };

  const setBarData = () => {
    const colors = [
      lita(1, aldursbil, nemandi[8]),
      lita(2, aldursbil, nemandi[9]),
      lita(3, aldursbil, nemandi[10]),
      lita(4, aldursbil, nemandi[11]),
      lita(5, aldursbil, nemandi[12]),
      lita(6, aldursbil, nemandi[13]),
      lita(7, aldursbil, nemandi[14]),
    ];

    const fillColors = [];
    const borderColors = [];
    colors.forEach((col) => {
      const color = classToColor(col);
      fillColors.push(color.fill);
      borderColors.push(color.border);
    });

    const fyrriFillColors = [];
    const fyrriBorderColors = [];
    if (fyrriData) {
      fyrriClass.forEach((col) => {
        const color = classToColor(col);
        fyrriFillColors.push(color.fill);
        fyrriBorderColors.push(color.border);
      });
    }

    const seinniFillColors = [];
    const seinniBorderColors = [];
    if (seinniData) {
      seinniClass.forEach((col) => {
        const color = classToColor(col);
        seinniFillColors.push(color.fill);
        seinniBorderColors.push(color.border);
      });
    }

    const datasets = [
      {
        label: '10% nemenda',
        data: [
          msf[aldursbil - 1][0],
          msf[aldursbil - 1][1],
          msf[aldursbil - 1][2],
          msf[aldursbil - 1][3],
          msf[aldursbil - 1][4],
          msf[aldursbil - 1][5],
          msf[aldursbil - 1][6],
        ],
        backgroundColor: 'rgba(241, 74, 74, 0.5)',
        borderWidth: 1,
        borderColor: 'rgb(241, 74, 74)',
        hoverBorderWidth: 3,
        hoverBorderColor: '#000',
        stack: 'Stack 0',
      },
      {
        label: '25% nemenda',
        data: [
          sf[aldursbil - 1][0] - msf[aldursbil - 1][0],
          sf[aldursbil - 1][1] - msf[aldursbil - 1][1],
          sf[aldursbil - 1][2] - msf[aldursbil - 1][2],
          sf[aldursbil - 1][3] - msf[aldursbil - 1][3],
          sf[aldursbil - 1][4] - msf[aldursbil - 1][4],
          sf[aldursbil - 1][5] - msf[aldursbil - 1][5],
          sf[aldursbil - 1][6] - msf[aldursbil - 1][6],
        ],
        backgroundColor: 'rgba(163, 159, 159, 0.5)',
        borderWidth: 1,
        borderColor: 'rgb(163, 159, 159)',
        hoverBorderWidth: 3,
        hoverBorderColor: '#000',
        fill: '-1',
        stack: 'Stack 0',
      },
      {
        label: '75% nemenda',
        data: [
          mf[aldursbil - 1][0] - sf[aldursbil - 1][0],
          mf[aldursbil - 1][1] - sf[aldursbil - 1][1],
          mf[aldursbil - 1][2] - sf[aldursbil - 1][2],
          mf[aldursbil - 1][3] - sf[aldursbil - 1][3],
          mf[aldursbil - 1][4] - sf[aldursbil - 1][4],
          mf[aldursbil - 1][5] - sf[aldursbil - 1][5],
          mf[aldursbil - 1][6] - sf[aldursbil - 1][6],
        ],
        backgroundColor: 'rgba(63, 151, 63, 0.5)',
        borderWidth: 1,
        borderColor: 'rgb(63, 151, 63)',
        hoverBorderWidth: 3,
        hoverBorderColor: '#000',
        fill: '-1',
        stack: 'Stack 0',
      },
      {
        label: '100% nemenda',
        data: [
          gf[aldursbil - 1][0] - mf[aldursbil - 1][0],
          gf[aldursbil - 1][1] - mf[aldursbil - 1][1],
          gf[aldursbil - 1][2] - mf[aldursbil - 1][2],
          gf[aldursbil - 1][3] - mf[aldursbil - 1][3],
          gf[aldursbil - 1][4] - mf[aldursbil - 1][4],
          gf[aldursbil - 1][5] - mf[aldursbil - 1][5],
          gf[aldursbil - 1][6] - mf[aldursbil - 1][6],
        ],
        backgroundColor: 'rgba(97, 97, 221, 0.5)',
        borderWidth: 1,
        borderColor: 'rgb(97, 97, 221)',
        hoverBorderWidth: 3,
        hoverBorderColor: '#000',
        fill: '-1',
        stack: 'Stack 0',
      },
      {
        label: 'Frammistaða nemanda',
        data: [
          nemandi[8],
          nemandi[9],
          nemandi[10],
          nemandi[11],
          nemandi[12],
          nemandi[13],
          nemandi[14],
        ],
        backgroundColor: [
          fillColors[0],
          fillColors[1],
          fillColors[2],
          fillColors[3],
          fillColors[4],
          fillColors[5],
          fillColors[6],
        ],
        borderWidth: 1,
        borderColor: [
          borderColors[0],
          borderColors[1],
          borderColors[2],
          borderColors[3],
          borderColors[4],
          borderColors[5],
          borderColors[6],
        ],
        hoverBorderWidth: 3,
        hoverBorderColor: '#000',
        fill: '-1',
        stack: 'Stack 2',
      },
    ];

    if (fyrriData) {
      const fyrriDataset = {
        label: 'Fyrri frammistaða nemanda',
        data: [
          fyrriData[0],
          fyrriData[1],
          fyrriData[2],
          fyrriData[3],
          fyrriData[4],
          fyrriData[5],
          fyrriData[6],
        ],
        backgroundColor: [
          fyrriFillColors[0],
          fyrriFillColors[1],
          fyrriFillColors[2],
          fyrriFillColors[3],
          fyrriFillColors[4],
          fyrriFillColors[5],
          fyrriFillColors[6],
        ],
        borderWidth: 1,
        borderColor: [
          fyrriBorderColors[0],
          fyrriBorderColors[1],
          fyrriBorderColors[2],
          fyrriBorderColors[3],
          fyrriBorderColors[4],
          fyrriBorderColors[5],
          fyrriBorderColors[6],
        ],
        hoverBorderWidth: 3,
        hoverBorderColor: '#000',
        fill: '-1',
        stack: 'Stack 1',
      };

      datasets.push(fyrriDataset);
    }

    if (seinniData) {
      const seinniDataset = {
        label: 'Seinni frammistaða nemanda',
        data: [
          seinniData[0],
          seinniData[1],
          seinniData[2],
          seinniData[3],
          seinniData[4],
          seinniData[5],
          seinniData[6],
        ],
        backgroundColor: [
          seinniFillColors[0],
          seinniFillColors[1],
          seinniFillColors[2],
          seinniFillColors[3],
          seinniFillColors[4],
          seinniFillColors[5],
          seinniFillColors[6],
        ],
        borderWidth: 1,
        borderColor: [
          seinniBorderColors[0],
          seinniBorderColors[1],
          seinniBorderColors[2],
          seinniBorderColors[3],
          seinniBorderColors[4],
          seinniBorderColors[5],
          seinniBorderColors[6],
        ],
        hoverBorderWidth: 3,
        hoverBorderColor: '#000',
        fill: '-1',
        stack: 'Stack 1',
      };

      datasets.push(seinniDataset);
    }

    const barData = {
      labels: [
        'Rím',
        'Samstöfur',
        'Samsett orð',
        'Hljóðgreining',
        'Margræð orð',
        'Orðhlutaeyðing',
        'Hljóðtenging',
      ],
      datasets: datasets,
    };

    setData(barData);
  };

  const barMouseOver = () => {
    setBarHover(true);
  };

  const barMouseLeave = () => {
    setBarHover(false);
  };

  let barClass;
  if (barHover) {
    barClass = 'hoverOver';
  }

  return (
    <div>
      <div className='chartdiv'>
        <img
          onMouseOver={barMouseOver}
          onMouseLeave={barMouseLeave}
          src={infoIcon}
          alt='Upplýsingatákn'></img>
        <div className='hoverbox'>
          <div className={'hoverbox__bottom bar' + nemNum}>
            <Bar data={data} options={options} />
          </div>
          <div className={'hoverbox__top ' + barClass}>
            <p>
              Stöplarnir koma tveir og tveir saman fyrir hvern þátt. 
              Vinstri stöpullinn sýnir hámarks stigafjölda í hverjum þætti 
              auk prósentuskiptingu á milli færniviðmiða. Hægri stöpullinn sýnir 
              hins vegar frammistöðu nemandans.
            </p>
            <p>
              Hér sést skýrar hvar innan færniviðmiðsins nemandinn lendir á hverjum þætti. 
              Ef stöpul fyrir nemanda vantar þá fékk nemandinn 0 stig í þeim þætti.

            </p>
            <p>
              Dreifing færniviðmiða í hverjum þætti er mismunandi eftir niðurstöðum stöðlunarúrtaks og 
              því ójöfn á milli einstakra þátta. 
              Litur hægri stöpulsins sýnir skýrt á hvaða færniviðmiði nemandinn lendir.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
