import React, { Component } from 'react';
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';

// Sass
import './ResultsEinstaklings.scss';

// Components
import Button from '../button/Button';
import ResultsEinstaklingsTafla from '../resultsEinstaklingsTafla/ResultsEinstaklingsTafla.jsx';
import ResultsEinstaklingsBar from '../resultEinstaklingsBar/ResultEinstaklingsBar.jsx';
import ResultsEinstaklingsRadar from '../resultEinstaklingsRadar/ResultEinstaklingsRadar.jsx';

// Assets
import { lita } from '../../assets/js/functions';
import arrowup from '../../assets/img/arrow-up.svg';
import arrowdown from '../../assets/img/arrow-down.svg';
import pdfIcon from '../../assets/img/pdf-icon.svg';
import getMMSLogo from './logo';

class ResultsEinstaklings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fjoldi: localStorage.getItem('fjoldiIProfi'),
      prevData: false,
      arrowupClass: 'hide',
      arrowdownClass: '',
      nemNum: this.props.nem,
      medalArray: [],
      radarHover: false,
      open: false,
      fyrriGogn: false,
      seinniGogn: false,
    };

    this.open = this.open.bind(this);
    this.calcMedalArray = this.calcMedalArray.bind(this);
    this.makePDF = this.makePDF.bind(this);
    this.resultsEinstaklingsTaflaCB =
      this.resultsEinstaklingsTaflaCB.bind(this);
  }

  async componentDidMount() {
    const nemNum = this.state.nemNum;
    const umsogn = localStorage.getItem(`umsogn${nemNum}`);
    const nemandi = localStorage.getItem(`nemandi${nemNum}`).split(',');
    const aldursbil = parseInt(nemandi[7]);
    const logo = getMMSLogo();

    await this.setState({ nemandi, aldursbil, logo, umsogn });

    // this.parseMedalArray();
    this.calcMedalArray();
  }

  async componentDidUpdate(prevProps, prevState) {
    const nemNum = this.state.nemNum;
    if (prevProps.open !== this.props.open && this.props.open === nemNum) {
      if (!this.state.open) {
        this.open();
      }
      this.props.openedCB(nemNum);
    }
    if (
      prevProps.makePDF !== this.props.makePDF &&
      this.props.makePDF === nemNum
    ) {
      setTimeout(() => {
        this.makePDF('all');
      }, 500);
    }
  }

  /*
  Notar aldursbil, fjölda og frammistöðu allra nemenda til að finna meðaltal
  í hverjum flokk fyrir sig
  */
  calcMedalArray() {
    const { aldursbil, fjoldi } = this.state;
    const rim = [];
    const samstofur = [];
    const samsett = [];
    const hljodgreining = [];
    const margraed = [];
    const ordhlutaeyding = [];
    const hljodtenging = [];
    for (let i = 0; i < fjoldi; i++) {
      const nemandi = localStorage.getItem(`nemandi${i + 1}`).split(',');
      if (parseInt(nemandi[7]) === aldursbil) {
        rim[i] = parseInt(nemandi[8]);
        samstofur[i] = parseInt(nemandi[9]);
        samsett[i] = parseInt(nemandi[10]);
        hljodgreining[i] = parseInt(nemandi[11]);
        margraed[i] = parseInt(nemandi[12]);
        ordhlutaeyding[i] = parseInt(nemandi[13]);
        hljodtenging[i] = parseInt(nemandi[14]);
      }
    }
    const reducer = (accumulator, curr) => accumulator + curr;
    if (rim[0]) {
      const medalArray = [
        rim.reduce(reducer) / fjoldi,
        samstofur.reduce(reducer) / fjoldi,
        samsett.reduce(reducer) / fjoldi,
        hljodgreining.reduce(reducer) / fjoldi,
        margraed.reduce(reducer) / fjoldi,
        ordhlutaeyding.reduce(reducer) / fjoldi,
        hljodtenging.reduce(reducer) / fjoldi,
      ];
      this.setState({ medalArray });
    } else {
      this.setState({ enginnAAldursbili: true });
    }
  }

  /*
  Fall sem tekur við gögnum frá ResultsEinstaklingsTafla sem tekur gögn frá FyrriSeinni.
  Setur í state gögn úr fyrri eða seinni athugun svo að hægt sé að bæta þeim inn á 
  radar og línurit.
  */
  resultsEinstaklingsTaflaCB(who, gogn) {
    if (who === 'fyrri') {
      this.setState({ fyrriGogn: gogn });
    } else if (who === 'seinni') {
      this.setState({ seinniGogn: gogn });
    }
  }

  // Opnar og lokar hverju formi fyrir sig
  open() {
    if (this.state.open) {
      this.setState({ open: false, arrowdownClass: '', arrowupClass: 'hide' });
    } else if (!this.state.open) {
      this.setState({ open: true, arrowdownClass: 'hide', arrowupClass: '' });
    }
  }

  async makePDF(x) {
    const { nemNum, nemandi, logo, fyrriGogn, seinniGogn } = this.state;

    // Gera PDF skjalið
    const doc = new jsPDF();
    // Setja inn logo og fyrirsögn
    doc.setFontSize(12);
    doc.addImage(logo, 150, 20, 310 / 6.5, 112 / 6.5);
    doc.text(15, 50, 'Frammistöðuskýrsla úr HLJÓM-2 - ' + nemandi[1]);

    // Setja inn dagsetningu
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    today = dd + '/' + mm + '/' + yyyy;
    doc.text(15, 35, today);

    // Setja inn heildarframmistöðu

    const frammistada = lita('heild', parseInt(nemandi[7]), nemandi[15]);
    if (fyrriGogn || seinniGogn) {
      if (fyrriGogn) {
        // Setja inn fyrri niðurstöðu
        if (fyrriGogn[16] === 'msf') {
          doc.setDrawColor(241, 74, 74);
          doc.setFillColor(248, 164, 164);
          doc.rect(137, 40, 60, 6, 'FD');
          doc.text(139, 44, 'Fyrri athugun: Mjög slök færni');
        } else if (fyrriGogn[16] === 'sf') {
          doc.setDrawColor(163, 159, 159);
          doc.setFillColor(209, 207, 207);
          doc.rect(137, 40, 60, 6, 'FD');
          doc.text(139, 44, 'Fyrri athugun: Slök færni');
        } else if (fyrriGogn[16] === 'mf') {
          doc.setDrawColor(63, 151, 63);
          doc.setFillColor(159, 203, 159);
          doc.rect(137, 40, 60, 6, 'FD');
          doc.text(139, 44, 'Fyrri athugun: Meðalfærni');
        } else if (fyrriGogn[16] === 'gf') {
          doc.setDrawColor(97, 97, 221);
          doc.setFillColor(176, 176, 238);
          doc.rect(137, 40, 60, 6, 'FD');
          doc.text(139, 44, 'Fyrri athugun: Góð færni');
        }

        // Setja inn seinni (núverandi) niðurstöðu
        if (frammistada === 'msf') {
          doc.setDrawColor(241, 74, 74);
          doc.setFillColor(248, 164, 164);
          doc.rect(137, 48, 60, 6, 'FD');
          doc.text(139, 52, 'Seinni athugun: Mjög slök færni');
        } else if (frammistada === 'sf') {
          doc.setDrawColor(163, 159, 159);
          doc.setFillColor(209, 207, 207);
          doc.rect(137, 48, 60, 6, 'FD');
          doc.text(139, 52, 'Seinni athugun: Slök færni');
        } else if (frammistada === 'mf') {
          doc.setDrawColor(63, 151, 63);
          doc.setFillColor(159, 203, 159);
          doc.rect(137, 48, 60, 6, 'FD');
          doc.text(139, 52, 'Seinni athugun: Meðalfærni');
        } else if (frammistada === 'gf') {
          doc.setDrawColor(97, 97, 221);
          doc.setFillColor(176, 176, 238);
          doc.rect(137, 48, 60, 6, 'FD');
          doc.text(139, 52, 'Seinni athugun: Góð færni');
        }
      } else if (seinniGogn) {
        // Setja inn fyrri (núverandi) niðurstöðu
        if (frammistada === 'msf') {
          doc.setDrawColor(241, 74, 74);
          doc.setFillColor(248, 164, 164);
          doc.rect(137, 40, 60, 6, 'FD');
          doc.text(139, 44, 'Fyrri athugun: Mjög slök færni');
        } else if (frammistada === 'sf') {
          doc.setDrawColor(163, 159, 159);
          doc.setFillColor(209, 207, 207);
          doc.rect(137, 40, 60, 6, 'FD');
          doc.text(139, 44, 'Fyrri athugun: Slök færni');
        } else if (frammistada === 'mf') {
          doc.setDrawColor(63, 151, 63);
          doc.setFillColor(159, 203, 159);
          doc.rect(137, 40, 60, 6, 'FD');
          doc.text(139, 44, 'Fyrri athugun: Meðalfærni');
        } else if (frammistada === 'gf') {
          doc.setDrawColor(97, 97, 221);
          doc.setFillColor(176, 176, 238);
          doc.rect(137, 40, 60, 6, 'FD');
          doc.text(139, 44, 'Fyrri athugun: Góð færni');
        }
        // Setja inn seinni niðurstöðu
        if (seinniGogn[16] === 'msf') {
          doc.setDrawColor(241, 74, 74);
          doc.setFillColor(248, 164, 164);
          doc.rect(137, 48, 60, 6, 'FD');
          doc.text(139, 52, 'Seinni athugun: Mjög slök færni');
        } else if (seinniGogn[16] === 'sf') {
          doc.setDrawColor(163, 159, 159);
          doc.setFillColor(209, 207, 207);
          doc.rect(137, 48, 60, 6, 'FD');
          doc.text(139, 52, 'Seinni athugun: Slök færni');
        } else if (seinniGogn[16] === 'mf') {
          doc.setDrawColor(63, 151, 63);
          doc.setFillColor(159, 203, 159);
          doc.rect(137, 48, 60, 6, 'FD');
          doc.text(139, 52, 'Seinni athugun: Meðalfærni');
        } else if (seinniGogn[16] === 'gf') {
          doc.setDrawColor(97, 97, 221);
          doc.setFillColor(176, 176, 238);
          doc.rect(137, 48, 60, 6, 'FD');
          doc.text(139, 52, 'Seinni athugun: Góð færni');
        }
      }
    } else {
      if (frammistada === 'msf') {
        doc.setDrawColor(241, 74, 74);
        doc.setFillColor(248, 164, 164);
        doc.rect(137, 44, 60, 10, 'FD');
        doc.text(152, 50, 'Mjög slök færni');
      } else if (frammistada === 'sf') {
        doc.setDrawColor(163, 159, 159);
        doc.setFillColor(209, 207, 207);
        doc.rect(137, 44, 60, 10, 'FD');
        doc.text(157, 50, 'Slök færni');
      } else if (frammistada === 'mf') {
        doc.setDrawColor(63, 151, 63);
        doc.setFillColor(159, 203, 159);
        doc.rect(137, 44, 60, 10, 'FD');
        doc.text(157, 50, 'Meðalfærni');
      } else if (frammistada === 'gf') {
        doc.setDrawColor(97, 97, 221);
        doc.setFillColor(176, 176, 238);
        doc.rect(137, 44, 60, 10, 'FD');
        doc.text(157, 50, 'Góð færni');
      }
    }

    // Setja inn töflu
    const nemTafla = document.querySelector('.einstaklings__tafla' + nemNum);
    const preW = nemTafla.offsetWidth;
    const preML = nemTafla.marginLeft;
    const preMT = nemTafla.marginTop;
    nemTafla.style = 'width: 419px; margin-left: 0; margin-top: 0';
    const w = nemTafla.offsetWidth;
    const h = nemTafla.offsetHeight;

    // Setja inn umsögn
    const umsogn = this.state.umsogn;
    if (umsogn) {
      doc.setFontSize(10);
      const splitTitle = doc.splitTextToSize(umsogn, 80);
      doc.text(110, 68, splitTitle);
    }

    // Setja inn radar
    const nemRadar = document.querySelector('.radar' + nemNum);

    // Setja inn Stöplarit
    const nemStopla = document.querySelector('.bar' + nemNum);

    if (doc) {
      domtoimage.toPng(nemTafla).then((imgData) => {
        doc.addImage(imgData, 'PNG', 15, 60, w / 5, h / 5);
        nemTafla.style = `width: ${preW}; margin-left: ${preML}; margin-top: ${preMT};`;
      });

      domtoimage.toPng(nemRadar).then((imgData) => {
        doc.addImage(imgData, 'PNG', 35, 130, 829 / 6, 414 / 6);
      });

      domtoimage.toPng(nemStopla).then((imgData) => {
        doc.addImage(imgData, 'PNG', 35, 210, 829 / 6, 414 / 6);
        doc.save(nemandi[1] + '-Frammistada.pdf');
        if (x === 'all') {
          this.open();
          this.props.pdfCB(nemNum);
        }
      });
    }
  }

  render() {
    const {
      arrowupClass,
      arrowdownClass,
      nemNum,
      nemandi,
      umsogn,
      fyrriGogn,
      seinniGogn,
    } = this.state;

    let nafn;
    let litur;
    if (nemandi) {
      nafn = nemandi[1];
      litur = lita('heild', parseInt(nemandi[7]), nemandi[15]);
    }

    return (
      <div className='resultsEinstaklings'>
        <div>
          <div>
            <div className='myndaDiv'>
              <img
                className={arrowupClass}
                onClick={this.open}
                src={arrowup}
                alt='Ör upp'></img>
              <img
                className={arrowdownClass}
                onClick={this.open}
                src={arrowdown}
                alt='Ör niður'></img>
            </div>
          </div>
          <div className='textaDiv'>
            <p className={litur}>{nafn}</p>
          </div>
        </div>
        <div className={`content ${arrowupClass}`}>
          <div className='umsognNem'>
            <div className='downloadNem'>
              <img
                className='downloadNem__pdf'
                src={pdfIcon}
                onClick={() => {
                  this.makePDF('justOne');
                }}
                alt='PDF icon'
              />
              <Button
                onClick={() => {
                  this.makePDF('justOne');
                }}
                children='Sækja upplýsingar fyrir nemanda'
              />
            </div>
          </div>
          <p className='umsogn'>{umsogn}</p>
          <ResultsEinstaklingsTafla
            nemNum={nemNum}
            resultsEinstaklingsTaflaCB={this.resultsEinstaklingsTaflaCB}
          />
          <ResultsEinstaklingsRadar
            nemNum={nemNum}
            fyrri={fyrriGogn}
            seinni={seinniGogn}
          />
          <ResultsEinstaklingsBar
            nemNum={nemNum}
            fyrri={fyrriGogn}
            seinni={seinniGogn}
          />
        </div>
      </div>
    );
  }
}

export default ResultsEinstaklings;
