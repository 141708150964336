import React, { useState } from 'react';

// CSS
import './NumStudents.scss';

// Components
import Button from '../button';

export default function NumStudents(props) {
  const [num, setNum] = useState(0);
  const [error, setError] = useState('');


  const handleNumChange = (e) => {
    if (e.target.value <= 100) {
      setNum(e.target.value)
      setError('')
    } else {
      setError('Tala verður að vera minni en 100')
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem('fjoldiIProfi', num);
    localStorage.setItem('doneNem', 0);
    const startDate = new Date();
    localStorage.setItem('startDate', startDate.toISOString().split('T')[0]);
    props.numStudentsCB(num);
  };

  let errorElement;
  if (error) {
    errorElement = <p className="incorrect">{error}</p>
  }

  return (
    <div className='numStudents' data-testid='numStudents'>
      <h2>Hversu margir nemendur munu þreyta prófið?</h2>
        {errorElement}
      <form>
        <input
          type='number'
          onChange={handleNumChange}
          min='1'
          placeholder='0'
          data-testid='numStudents-input'></input>
        <Button
          onClick={handleSubmit}
          children='ok'
          dataTestid='numStudents-submit'
        />
      </form>
    </div>
  );
}
