import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Sass
import './SkraFormDelete.scss';

// Components
import Button from '../button/Button';

export default function SkraFormDelete(props) {
  const [num, setNum] = useState(props.num);
  const [fjoldi] = useState(localStorage.getItem('fjoldiIProfi'));
  const navigate = useNavigate(); // Use useNavigate hook

  useEffect(() => {
    setNum(props.num);
  }, [props]);

  /*
  Eyðir formi úr lista, biður um samþyggi
  */
  const handleDelete = (e) => {
    e.preventDefault();
    const confirmation = window.confirm(
      'Þessi aðgerð eyðir þessu formi og öllum upplýsingum í því. Ertu viss um að vilja halda áfram?'
    );
    if (confirmation) {
      // Ef verið er að henda tómu formi
      if (localStorage.getItem(`nemandi${num}`)) {
        const doneNem = localStorage.getItem('doneNem');
        localStorage.setItem('doneNem', parseInt(doneNem) - 1);
      }
      for (let i = num; i <= fjoldi; i++) {
        // sækja n+1
        const nemandiIPlusEinn = localStorage.getItem('nemandi' + (i + 1));
        const umsognIPlusEinn = localStorage.getItem('umsogn' + (i + 1));

        // setja n = n+1
        if (nemandiIPlusEinn) {
          localStorage.setItem(`nemandi${i}`, nemandiIPlusEinn);
          localStorage.setItem(`umsogn${i}`, umsognIPlusEinn);
        } else {
          localStorage.removeItem(`nemandi${i}`);
          localStorage.removeItem(`umsogn${i}`);
        }
      }
      navigate('/'); // Use navigate instead of history.push
    }
  };

  return (
    <>
      <Button
        className='button__danger'
        onClick={handleDelete}
        children='Henda formi'
      ></Button>
    </>
  );
}
