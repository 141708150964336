import React, { Component } from 'react';

// Component og function
import ResultsEinstaklings from '../resultsEinstaklings/ResultsEinstaklings';
import Button from '../button/Button';

// Assets
import pdfIcon from '../../assets/img/pdf-icon.svg';

// CSS
import './ResultsNemenda.scss';
import arrowup from '../../assets/img/arrow-up.svg';
import arrowdown from '../../assets/img/arrow-down.svg';

class ResultsNemenda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrowupClass: 'hide',
      arrowdownClass: '',
      fjoldiIProfi: localStorage.getItem('fjoldiIProfi'),
      fjoldiArray: [],
      downloadNem: false,
      openAll: false,
      makePDF: false,
    };

    this.open = this.open.bind(this);
    this.openedCB = this.openedCB.bind(this);
    this.pdfCB = this.pdfCB.bind(this);
    this.saveAllPdf = this.saveAllPdf.bind(this);
  }

  componentDidMount() {
    const fjoldiIProfi = parseInt(this.state.fjoldiIProfi);

    const fjoldiArray = [];
    for (let i = 0; i < fjoldiIProfi; i++) {
      let nemandi = localStorage.getItem(`nemandi${i + 1}`);
      if (nemandi) {
        nemandi = nemandi.split(',');
        // nemandi[15] er heildarsuma stiga úr prófi
        fjoldiArray[i] = nemandi[15];
      }
    }

    this.setState({ fjoldiArray });
  }

  // Opnar og lokar hverju formi fyrir sig
  open() {
    if (this.state.open) {
      this.setState({ open: false, arrowdownClass: '', arrowupClass: 'hide' });
    } else if (!this.state.open) {
      this.setState({ open: true, arrowdownClass: 'hide', arrowupClass: '' });
    }
  }

  openedCB(nemNum) {
    this.setState({ makePDF: nemNum });
  }

  pdfCB(nemNum) {
    const fjoldi = this.state.fjoldiIProfi;
    if (parseInt(nemNum) < parseInt(fjoldi)) {
      this.setState({ openAll: parseInt(nemNum) + 1 });
    }
  }

  saveAllPdf() {
    const ok = window.confirm(
      'Þessi aðgerð vistar eitt .pdf skjal fyrir hvern nemanda. Skjölin má síðan finna í downloads möppu. Vistun getur tekið 2-5 sek á hvern nemanda. Ekki eiga við síðuna fyrr en öll skjöl hafa verið vistuð.'
    );
    if (ok) {
      this.setState({ openAll: 1 });
    }
  }

  render() {
    const { arrowupClass, arrowdownClass, fjoldiArray } = this.state;

    let content = fjoldiArray.map((val, i) => {
      return (
        <ResultsEinstaklings
          open={this.state.openAll}
          openedCB={this.openedCB}
          makePDF={this.state.makePDF}
          pdfCB={this.pdfCB}
          nem={i + 1}
          key={i + 1}></ResultsEinstaklings>
      );
    });

    return (
      <div className='resultsNemenda col col-10 offset-col-1 col-md-12 offset-col-md-0'>
        <div>
          <div>
            <div className='myndaDiv'>
              <img
                className={arrowupClass}
                onClick={this.open}
                src={arrowup}
                alt='Ör upp'></img>
              <img
                className={arrowdownClass}
                onClick={this.open}
                src={arrowdown}
                alt='Ör niður'></img>
            </div>
          </div>
          <div className='textaDiv'>
            <p className='neutral'>Frammistaða stakra nemenda</p>
          </div>
        </div>
        <div className={`nemendaListi ${arrowupClass}`}>
          <div className='downloadDiv'>
            <img
              className='pdfIcon'
              src={pdfIcon}
              alt='Merki fyrir PDF'
              onClick={this.saveAllPdf}
            />
            <Button
              onClick={this.saveAllPdf}
              children='Sækja niðurstöður allra á .pdf formi'
            />
          </div>
          {content}
        </div>
      </div>
    );
  }
}

export default ResultsNemenda;
