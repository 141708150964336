import React, { Component } from 'react';

// Sass
import './SkraForm.scss';

// Components
import Button from '../button';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import SkraFormDelete from '../skraFormDelete/SkraFormDelete';
import Modal from '../modal/Modal';
import Backdrop from '../backdrop/Backdrop.jsx';

// Assets
import {
  validateKennitala,
  calcAldursbil,
  getAldursbilText,
  lita,
  aldursbilString,
  getTwoDates,
} from '../../assets/js/functions';

// Myndir og assets
import infoIcon from '../../assets/img/information.svg';
import arrowup from '../../assets/img/arrow-up.svg';
import arrowdown from '../../assets/img/arrow-down.svg';

class SkraForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      open: false,
      arrowupClass: 'hide',
      arrowdownClass: '',
      nafn: '',
      kennitala: '',
      annadMal: false,
      validkt: 'start',
      aldursbilTextClass: '',
      aldursbil: false,
      aldurDagar: '',
      dagsetning: new Date(),
      DateFrom: null,
      DateTo: null,
      kyn: ' ',
      rim: '',
      samstofur: '',
      samsett: '',
      hljodgreining: '',
      margraed: '',
      ordhlutaeyding: '',
      hljodtenging: '',
      total: '',
      umsogn: '',
      litur1: 'neutral',
      litur2: 'neutral',
      litur3: 'neutral',
      litur4: 'neutral',
      litur5: 'neutral',
      litur6: 'neutral',
      litur7: 'neutral',
      liturHeild: 'neutral',
      calculated: false,
      modalOpen: false,
      modalProps: '',
      dagsetningError: false,
      errorText: [
        null, // 0: nafn
        null, // 1: kennitala
        null, // 2: dagsetning
        null, // 3: Rím
        null, // 4: Samstöfur
        null, // 5: Samsett orð
        null, // 6: Hljóðgreining
        null, // 7: Margræð orð
        null, // 8: Orðhlutaeyðing
        null, // 9: Hljóðtenging
        null, // 10: Annað
      ],
    };

    this.handleRimChange = this.handleRimChange.bind(this);
    this.handleSamstofurChange = this.handleSamstofurChange.bind(this);
    this.handleSamsettChange = this.handleSamsettChange.bind(this);
    this.handleHljodgreiningChange = this.handleHljodgreiningChange.bind(this);
    this.handleMargraedChange = this.handleMargraedChange.bind(this);
    this.handleOrdhlutaeydingChange =
      this.handleOrdhlutaeydingChange.bind(this);
    this.handleHljodtengingChange = this.handleHljodtengingChange.bind(this);
    this.open = this.open.bind(this);
    this.handleNafnChange = this.handleNafnChange.bind(this);
    this.handleKennitalaChange = this.handleKennitalaChange.bind(this);
    this.handleAnnadMalChange = this.handleAnnadMalChange.bind(this);
    this.handleDagsetningChange = this.handleDagsetningChange.bind(this);
    this.colorAll = this.colorAll.bind(this);
    this.handleRimChange = this.handleRimChange.bind(this);
    this.handleSamstofurChange = this.handleSamstofurChange.bind(this);
    this.handleSamsettChange = this.handleSamsettChange.bind(this);
    this.handleHljodgreiningChange = this.handleHljodgreiningChange.bind(this);
    this.handleMargraedChange = this.handleMargraedChange.bind(this);
    this.handleOrdhlutaeydingChange =
      this.handleOrdhlutaeydingChange.bind(this);
    this.handleHljodtengingChange = this.handleHljodtengingChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.modalCB = this.modalCB.bind(this);
    this.clickCB = this.clickCB.bind(this);
  }

  // Fylla form með upplýsingum í LS ef þær eru til staðar
  async componentDidMount() {
    let nem;
    if (this.props.nem) {
      const num = this.props.num;
      const umsogn = localStorage.getItem(`umsogn${num}`);
      nem = this.props.nem.split(',');
      this.setState({
        nafn: nem[1],
        kennitala: nem[2],
        validkt: true,
        annadMal: nem[3] === 'true' ? true : false,
        dagsetning: new Date(nem[5]),
        aldurDagar: nem[6],
        aldursbil: parseInt(nem[7]),
        rim: nem[8],
        samstofur: nem[9],
        samsett: nem[10],
        hljodgreining: nem[11],
        margraed: nem[12],
        ordhlutaeyding: nem[13],
        hljodtenging: nem[14],
        umsogn,
      });
      this.colorAll(parseInt(nem[7]));
      this.calcHeild();
    }
    this.setState({ id: this.props.num });
  }

  modalCB() {
    this.setState({ modalOpen: false });
  }

  // Tekur við upplýsingum frá backdrop um að klikkað hafi verið á það
  clickCB() {
    this.setState({ modalOpen: false });
  }

  // Opnar og lokar hverju formi fyrir sig
  open() {
    if (this.state.open) {
      this.setState({ open: false, arrowdownClass: '', arrowupClass: 'hide' });
    } else if (!this.state.open) {
      this.setState({ open: true, arrowdownClass: 'hide', arrowupClass: '' });
    }
  }

  handleNafnChange(e) {
    let nafn = e.target.value.split(',')[0];
    nafn = nafn.split('<')[0];
    nafn = nafn.split('>')[0];
    nafn = nafn.split(';')[0];
    nafn = nafn.split('$')[0];
    nafn = nafn.split('"')[0];
    nafn = nafn.split('%')[0];
    nafn = nafn.split('#')[0];
    nafn = nafn.split('{')[0];
    nafn = nafn.split('}')[0];
    nafn = nafn.split("'")[0];
    nafn = nafn.split('&')[0];
    nafn = nafn.split('|')[0];
    const errorText = this.state.errorText;
    if (nafn.length <= 64) {
      errorText[0] = null;
      this.setState({ nafn, errorText });
      this.colorAll(this.state.aldursbil);
    } else {
      errorText[0] = 'Nafn verður að vera innan við 64 stafir';
      this.setState({ errorText });
    }
  }

  handleKennitalaChange(e) {
  const errorText = this.state.errorText;
  let kennitala = e.target.value.split('-')[0];
  kennitala = kennitala.split(' ')[0];
  this.setState({ kennitala });
  if (kennitala.length === 10) {
    errorText[1] = null;
    if (validateKennitala(kennitala)) {
      this.setState({ validkt: true });
      const { DateFrom, DateTo } = getTwoDates(kennitala);
      this.setState({ DateFrom, DateTo });
      const dagsetning = this.state.dagsetning;
      if (dagsetning) {
        const { aldursbil, aldurDagar } = calcAldursbil(
          kennitala,
          dagsetning.toISOString().split('T')[0].split('-'),
        );
        let aldursbilTextClass;
        if (
          aldursbil === 1 ||
          aldursbil === 2 ||
          aldursbil === 3 ||
          aldursbil === 4
        ) {
          aldursbilTextClass = 'correct';
        } else {
          aldursbilTextClass = 'incorrect';
        }
        this.setState({
          aldursbil,
          aldurDagar,
          aldursbilTextClass,
          errorText,
        });
        this.colorAll(aldursbil);
      }
    } else {
      this.setState({ validkt: false });
    }
  } else if (kennitala.length > 10) {
    errorText[1] = 'Kennitala má ekki vera lengri en 10 tölustafir';
    this.setState({ errorText, validkt: false });
  }
}

  handleAnnadMalChange(e) {
    this.setState({ annadMal: e.target.checked });
  }

  handleDagsetningChange(e) {
    if (e) {
      if (e > new Date()) {
        this.setState({
          dagsetningError: 'Prófdagur má ekki vera fram í tímann',
        });
      } else {
        const dagsetning = e?.toISOString().split('T')[0];
        this.setState({ dagsetning: e, dagsetningError: false });
        const validkt = this.state.validkt;
        if (validkt && validkt !== 'start' && dagsetning) {
          const { aldursbil, aldurDagar } = calcAldursbil(
            this.state.kennitala,
            dagsetning.split('-'),
          );
          let aldursbilTextClass;
          if (
            aldursbil === 1 ||
            aldursbil === 2 ||
            aldursbil === 3 ||
            aldursbil === 4
          ) {
            aldursbilTextClass = 'correct';
          } else {
            aldursbilTextClass = 'incorrect';
          }
          this.setState({ aldursbil, aldurDagar, aldursbilTextClass });
          this.colorAll(aldursbil);
        }
      }
    }
  }

  // Setur CSS litaklasa á öll dropdown sem búið var að fylla inn
  // áður en að kennitala og prófdagsetning voru komin. Ljótt fall
  // endurtekning á kóða, laga við tækifæri.
  // Tekur  : Tölu (1-4) sem samsvarar aldursbili nemanda.
  // Skilar : Engu.
  colorAll(aldursbil) {
    if (this.state.rim) {
      const litur = lita(1, aldursbil, this.state.rim);
      this.setState({ litur1: litur });
    }
    if (this.state.samstofur) {
      const litur = lita(2, aldursbil, this.state.samstofur);
      this.setState({ litur2: litur });
    }
    if (this.state.samsett) {
      const litur = lita(3, aldursbil, this.state.samsett);
      this.setState({ litur3: litur });
    }
    if (this.state.hljodgreining) {
      const litur = lita(4, aldursbil, this.state.hljodgreining);
      this.setState({ litur4: litur });
    }
    if (this.state.margraed) {
      const litur = lita(5, aldursbil, this.state.margraed);
      this.setState({ litur5: litur });
    }
    if (this.state.ordhlutaeyding) {
      const litur = lita(6, aldursbil, this.state.ordhlutaeyding);
      this.setState({ litur6: litur });
    }
    if (this.state.hljodtenging) {
      const litur = lita(7, aldursbil, this.state.hljodtenging);
      this.setState({ litur7: litur });
    }
  }

  handleRimChange(e) {
    const errorText = this.state.errorText;
    if (parseInt(e.target.value) <= 12) {
      errorText[3] = null;
      this.setState({ rim: e.target.value, errorText });
      if (this.state.validkt && this.state.dagsetning) {
        const litur = lita(1, this.state.aldursbil, e.target.value);
        this.setState({ litur1: litur });
      }
    } else {
      errorText[3] = 'Rím verður að vera á bilinu 0-12';
      this.setState({ errorText });
    }
  }

  handleSamstofurChange(e) {
    const errorText = this.state.errorText;
    if (parseInt(e.target.value) <= 8) {
      errorText[4] = null;
      this.setState({ samstofur: e.target.value, errorText });
      if (this.state.validkt && this.state.dagsetning) {
        const litur = lita(2, this.state.aldursbil, e.target.value);
        this.setState({ litur2: litur });
      }
    } else {
      errorText[4] = 'Samstöfur verður að vera á bilinu 0-8';
      this.setState({ errorText });
    }
  }

  handleSamsettChange(e) {
    const errorText = this.state.errorText;
    if (parseInt(e.target.value) <= 10) {
      errorText[5] = null;
      this.setState({ samsett: e.target.value, errorText });
      if (this.state.validkt && this.state.dagsetning) {
        const litur = lita(3, this.state.aldursbil, e.target.value);
        this.setState({ litur3: litur });
      }
    } else {
      errorText[5] = 'Samsett orð verða að vera á bilinu 0-10';
      this.setState({ errorText });
    }
  }

  handleHljodgreiningChange(e) {
    const errorText = this.state.errorText;
    if (parseInt(e.target.value) <= 15) {
      errorText[6] = null;
      this.setState({ hljodgreining: e.target.value, errorText });
      if (this.state.validkt && this.state.dagsetning) {
        const litur = lita(4, this.state.aldursbil, e.target.value);
        this.setState({ litur4: litur });
      }
    } else {
      errorText[6] = 'Hljóðgreining verður að vera á bilinu 0-15';
      this.setState({ errorText });
    }
  }

  handleMargraedChange(e) {
    const errorText = this.state.errorText;
    if (parseInt(e.target.value) <= 8) {
      errorText[7] = null;
      this.setState({ margraed: e.target.value, errorText });
      if (this.state.validkt && this.state.dagsetning) {
        const litur = lita(5, this.state.aldursbil, e.target.value);
        this.setState({ litur5: litur });
      }
    } else {
      errorText[7] = 'Margræð orð verða að vera á bilinu 0-8';
      this.setState({ errorText });
    }
  }

  handleOrdhlutaeydingChange(e) {
    const errorText = this.state.errorText;
    if (parseInt(e.target.value) <= 10) {
      errorText[8] = null;
      this.setState({ ordhlutaeyding: e.target.value, errorText });
      if (this.state.validkt && this.state.dagsetning) {
        const litur = lita(6, this.state.aldursbil, e.target.value);
        this.setState({ litur6: litur });
      }
    } else {
      errorText[8] = 'Orðhlutaeyðing verður að vera á bilinu 0-10';
      this.setState({ errorText });
    }
  }

  handleHljodtengingChange(e) {
    const errorText = this.state.errorText;
    if (parseInt(e.target.value) <= 8) {
      errorText[9] = null;
      this.setState({ hljodtenging: e.target.value, errorText });
      if (this.state.validkt && this.state.dagsetning) {
        const litur = lita(7, this.state.aldursbil, e.target.value);
        this.setState({ litur7: litur });
      }
    } else {
      errorText[9] = 'Hljóðtenging verður að vera á bilinu 0-8';
      this.setState({ errorText });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    // Bara reikna ef búið er að fylla inn í allt formið
    const {
      id,
      nafn,
      kennitala,
      validkt,
      annadMal,
      kyn,
      dagsetning,
      aldurDagar,
      aldursbil,
      rim,
      samstofur,
      samsett,
      hljodgreining,
      margraed,
      ordhlutaeyding,
      hljodtenging,
      umsogn,
    } = this.state;

    if (
      Boolean(
        nafn &&
          validkt &&
          dagsetning &&
          rim &&
          samstofur &&
          samsett &&
          hljodgreining &&
          margraed &&
          ordhlutaeyding &&
          hljodtenging,
      )
    ) {
      // Summa stiga úr prófi
      const sum =
        parseInt(rim) +
        parseInt(samstofur) +
        parseInt(samsett) +
        parseInt(hljodgreining) +
        parseInt(margraed) +
        parseInt(ordhlutaeyding) +
        parseInt(hljodtenging);

      // Finna lit/færniflokk nemanda
      const litur = lita('heild', aldursbil, sum);

      // Setja summu og lit í state
      this.setState({ total: sum, liturHeild: litur });

      const parsedDagsetning = dagsetning.toISOString().split('T')[0];

      let addOrUpdate;
      let uid;
      let updateDoneAndCurr;
      let nemandi = localStorage.getItem(`nemandi${id}`);
      if (nemandi) {
        nemandi = nemandi.split(',');
        if (nemandi[18]) {
          // Ef hérna inni, þá er búið að senda gögn á /results
          uid = nemandi[18];
          addOrUpdate = 'needsUpdating';
          updateDoneAndCurr = false;
        } else {
          // Ef hérna inni þá er verið að uppfæra formið án þess að fara á /results
          addOrUpdate = 'needsSending';
          updateDoneAndCurr = false;
        }
      } else {
        // Ef hérna inni þá er verið að reikna úr forminu í fyrsta skipti
        addOrUpdate = 'needsSending';
        updateDoneAndCurr = true;
      }

      const gogn = [
        'skra',
        nafn,
        kennitala,
        annadMal,
        kyn,
        parsedDagsetning,
        aldurDagar,
        aldursbil,
        rim,
        samstofur,
        samsett,
        hljodgreining,
        margraed,
        ordhlutaeyding,
        hljodtenging,
        sum,
        litur,
        addOrUpdate,
        uid,
      ];
      localStorage.setItem(`umsogn${id}`, umsogn);
      this.props.formSubmitCB(id, gogn, updateDoneAndCurr);
    }
  }

  // Fall sem leyfir bæði  ComponentDidMount að
  // Reikna niðurstöður fyrir formið.
  // Tekur  : Streng "submit" eða "mount" til að vita hvort eiga
  //          eigi við fjolda í LS eða ekki. Notar einnig
  //          gildi í state.
  // Skilar : Engu, uppfærir state þannig að niðurstöðu texti
  //          bætist við undir form og nafn litast.
  calcHeild() {
    const {
      aldursbil,
      rim,
      samstofur,
      samsett,
      hljodgreining,
      margraed,
      ordhlutaeyding,
      hljodtenging,
    } = this.state;

    // Reikna niðurstöðu
    const sum =
      parseInt(rim) +
      parseInt(samstofur) +
      parseInt(samsett) +
      parseInt(hljodgreining) +
      parseInt(margraed) +
      parseInt(ordhlutaeyding) +
      parseInt(hljodtenging);
    const litur = lita('heild', aldursbil, sum);
    this.setState({ total: sum, liturHeild: litur });

    // Senda upplýsingar á /skra til að setja í heildarvigra
    // this.props.infoCallback(gogn);

    this.setState({ calculated: true });
  }

  render() {
    const {
      id,
      arrowupClass,
      arrowdownClass,
      nafn,
      kennitala,
      annadMal,
      validkt,
      dagsetning,
      aldursbil,
      aldursbilTextClass,
      aldurDagar,
      rim,
      samstofur,
      samsett,
      hljodgreining,
      margraed,
      ordhlutaeyding,
      hljodtenging,
      total,
      umsogn,
      litur1,
      litur2,
      litur3,
      litur4,
      litur5,
      litur6,
      litur7,
      liturHeild,

      modalOpen,
      modalProps,
      dagsetningError,
      errorText,
    } = this.state;

    let nafnText;
    if (!nafn) {
      nafnText = 'Nemandi ' + id;
    } else {
      nafnText = nafn;
    }

    // Bæta við staðfestingu eða höfnun á kennitölu
    let ktNote;
    if (validkt === 'start') {
      ktNote = '';
    } else if (validkt) {
      ktNote = <p className='correct'>Gild kennitala</p>;
    } else {
      ktNote = <p className='incorrect'>Ekki gild kennitala</p>;
    }

    // Bæta við aldri í dögum og aldursbili
    let aldursbilText;
    if (aldursbil || aldursbil === 0) {
      aldursbilText = (
        <p className={aldursbilTextClass}>
          {getAldursbilText(aldursbil, aldurDagar)}
        </p>
      );
    }

    let dagsetningErrorElement;
    if (dagsetningError) {
      dagsetningErrorElement = <p className='incorrect'>{dagsetningError}</p>;
    }

    // Bæta niðurstöðum við
    let nidurstodur;
    if (liturHeild && liturHeild !== 'neutral') {
      nidurstodur = (
        <p className={liturHeild}>
          Nemandinn fékk {total} stig úr HLJÓM-2 athugun og telst búa yfir{' '}
          {aldursbilString(liturHeild)}.
        </p>
      );
    }

    let modal;
    let backdrop;
    if (modalOpen) {
      modal = <Modal tab={modalProps} modalCB={this.modalCB} />;
      backdrop = <Backdrop clickCB={this.clickCB} />;
    }

    let errorElement = errorText.map((err, i) => {
      return (
        <p className='incorrect' key={i}>
          {err}
        </p>
      );
    });

    return (
      <div className='skraForm'>
        {backdrop}
        {modal}
        <div>
          <div className='myndaDiv'>
            <img
              className={arrowupClass}
              onClick={this.open}
              src={arrowup}
              alt='Ör upp'></img>
            <img
              className={arrowdownClass}
              onClick={this.open}
              src={arrowdown}
              alt='Ör niður'></img>
          </div>
        </div>
        <div className={'textaDiv ' + arrowdownClass}>
          <p className={liturHeild + arrowdownClass}>{nafnText}</p>
        </div>
        <form className={arrowupClass}>
          {errorElement}
          <label>
            <p>Nafn nemanda</p>
            <input
              type='text'
              onChange={this.handleNafnChange}
              value={nafn}
              maxLength='64'></input>
          </label>
          <label>
            <div className='kennitoluTextaDiv'>
              <p>Kennitala nemanda</p>
              {ktNote}
            </div>
            <input
              type='text'
              onChange={this.handleKennitalaChange}
              value={kennitala}
              maxLength='10'></input>
          </label>
          <div className='annadMalDiv'>
            <label htmlFor='annadMal'>Íslenska sem annað mál</label>
            <input
              type='checkbox'
              id='annadMal'
              checked={annadMal}
              onChange={this.handleAnnadMalChange}></input>
          </div>
          <label>
            <div className='dagsetningarTextaDiv'>
              <p>Prófdagur</p>
              {aldursbilText}
            </div>
            {dagsetningErrorElement}
            <div className='datePickerDiv'>
              <DatePicker
  value={dagsetning}
  onChange={this.handleDagsetningChange}
  format='dd-MM-y'
  minDate={this.state.DateFrom}
  maxDate={this.state.DateTo}
  tileDisabled={({ date }) => date < this.state.DateFrom || date > this.state.DateTo}
  className="custom-date-picker"
/>
              <img
                src={infoIcon}
                alt='Upplýsingar um aldursbil'
                onClick={() => {
                  this.setState({ modalOpen: true, modalProps: 'aldursbil' });
                }}
              />
            </div>
          </label>
          <div className='dropDown'>
            <label htmlFor='1'>Rím</label>
            <div>
              <select
                className={litur1}
                id='1'
                onChange={this.handleRimChange}
                value={rim}>
                <option>-</option>
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
              </select>
              <img
                src={infoIcon}
                alt='Upplýsingar um töflu'
                onClick={() => {
                  this.setState({ modalOpen: true, modalProps: 'rim' });
                }}></img>
            </div>
          </div>
          <div className='dropDown'>
            <label htmlFor='2'>Samstöfur</label>
            <div>
              <select
                className={litur2}
                id='2'
                onChange={this.handleSamstofurChange}
                value={samstofur}>
                <option>-</option>
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
              </select>
              <img
                src={infoIcon}
                alt='Upplýsingar um töflu'
                onClick={() => {
                  this.setState({ modalOpen: true, modalProps: 'samstofur' });
                }}
              />
            </div>
          </div>
          <div className='dropDown'>
            <label htmlFor='3'>Samsett orð</label>
            <div>
              <select
                className={litur3}
                id='3'
                onChange={this.handleSamsettChange}
                value={samsett}>
                <option>-</option>
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
              </select>
              <img
                src={infoIcon}
                alt='Upplýsingar um töflu'
                onClick={() => {
                  this.setState({ modalOpen: true, modalProps: 'samsett' });
                }}
              />
            </div>
          </div>
          <div className='dropDown'>
            <label htmlFor='4'>Hljóðgreining</label>
            <div>
              <select
                className={litur4}
                id='4'
                onChange={this.handleHljodgreiningChange}
                value={hljodgreining}>
                <option>-</option>
                <option>0</option>
                <option>3</option>
                <option>6</option>
                <option>9</option>
                <option>12</option>
                <option>15</option>
              </select>
              <img
                src={infoIcon}
                alt='Upplýsingar um töflu'
                onClick={() => {
                  this.setState({
                    modalOpen: true,
                    modalProps: 'hljodgreining',
                  });
                }}
              />
            </div>
          </div>
          <div className='dropDown'>
            <label htmlFor='5'>Margræð orð</label>
            <div>
              <select
                className={litur5}
                id='5'
                onChange={this.handleMargraedChange}
                value={margraed}>
                <option>-</option>
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
              </select>
              <img
                src={infoIcon}
                alt='Upplýsingar um töflu'
                onClick={() => {
                  this.setState({ modalOpen: true, modalProps: 'margraed' });
                }}
              />
            </div>
          </div>
          <div className='dropDown'>
            <label htmlFor='6'>Orðhlutaeyðing</label>
            <div>
              <select
                className={litur6}
                id='6'
                onChange={this.handleOrdhlutaeydingChange}
                value={ordhlutaeyding}>
                <option>-</option>
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
              </select>
              <img
                src={infoIcon}
                alt='Upplýsingar um töflu'
                onClick={() => {
                  this.setState({
                    modalOpen: true,
                    modalProps: 'ordhlutaeyding',
                  });
                }}
              />
            </div>
          </div>
          <div className='dropDown'>
            <label htmlFor='7'>Hljóðtenging</label>
            <div>
              <select
                className={litur7}
                id='7'
                onChange={this.handleHljodtengingChange}
                value={hljodtenging}>
                <option>-</option>
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
              </select>
              <img
                src={infoIcon}
                alt='Upplýsingar um töflu'
                onClick={() => {
                  this.setState({
                    modalOpen: true,
                    modalProps: 'hljodtenging',
                  });
                }}
              />
            </div>
          </div>
          <div className='reviewDiv'>
            <label htmlFor='review'>
              Umsögn um nemanda (hámark 700 stafabil)
            </label>
            <textarea
              name='review'
              id='textarea'
              maxLength='700'
              value={umsogn}
              onChange={(e) => {
                this.setState({ umsogn: e.target.value });
              }}
              placeholder='Umsögn eða útskýringar um frammistöðu nemanda. Birtist í frammistöðuskjali.'
            />
          </div>
          <div className='submitDiv'>
            <Button onClick={this.handleSubmit}>Reikna niðurstöður</Button>
            <SkraFormDelete num={id} />
          </div>
          <div className='nidurstodur'>{nidurstodur}</div>
        </form>
      </div>
    );
  }
}

export default SkraForm;
