import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Routes
import Login from './routes/login/Login';
import Start from './routes/start/Start';
import Skra from './routes/skra/Skra';
import Results from './routes/results/Results';
import ChangePassword from './routes/changePassword/ChangePassword.jsx';
import Info from './routes/info/Info';

// Components
import Header from './components/header/Header.jsx';

// CSS
import './App.scss';
import './grid.scss';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  // Callback to handle login
  const loginCallback = (childData) => {
    setLoggedIn(childData);
  };

  return (
    <Router>
      <Header />
      <main className="App" data-testid="App">
        <div className="grid">
          <Routes>
            <Route path="/" element={<Start />} />
            <Route path="/login" element={<Login />} />
            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/skra" element={<Skra />} />
            <Route path="/results" element={<Results />} />
            <Route path="/info" element={<Info />} />
          </Routes>
        </div>
      </main>
    </Router>
  );
};

export default App;
